export interface CurrencyOption {
  code: string
  name: string
  symbol: string
  decimalPlaces: number
}

export function getCurrencySymbol(currencyCode: string) {
  return (0)
    .toLocaleString(undefined, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

// Sort the currencies by name alphabetically, but with USD as the first one if it exists.
export function sortCurrencyOptions(currencies: CurrencyOption[]): CurrencyOption[] {
  const usdCurrency = currencies.find((currency) => currency.code === 'USD')

  // Remove the USD currency from the array if it exists.
  if (usdCurrency) {
    currencies.splice(currencies.indexOf(usdCurrency), 1)
  }

  // Sort the remaining currencies by name alphabetically.
  currencies.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

  // Insert the USD currency at the start of the array if it existed originally.
  if (usdCurrency) {
    currencies.splice(0, 0, usdCurrency)
  }

  return currencies
}

export function FormatCurrency(currencyCode: string, amount: string): string {
  const formatter = new Intl.NumberFormat('default', {
    style: 'currency',
    currency: currencyCode,
  })

  const fractionalDigits = formatter.resolvedOptions().minimumFractionDigits
  if (fractionalDigits === 0) {
    return formatter.format(parseInt(amount))
  }

  return formatter.format(parseInt(amount) / 100)
}
