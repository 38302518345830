import { Form, Input } from 'antd'

import { FormItemContainer } from 'components/common/input/form-item-container'
import { ErrorMessages, FieldNames, StaticText } from 'components/pages/verify-email-page/email-form/constants'

export interface EmailAddressFormItemProps {
  isDisabled: boolean
}

export function EmailAddressFormItem({ isDisabled }: EmailAddressFormItemProps): JSX.Element {
  return (
    <FormItemContainer header={StaticText.EmailAddressHeader}>
      <Form.Item
        name={FieldNames.EmailAddress}
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          { required: true, message: ErrorMessages.EmptyEmailAddress },
          { type: 'email', message: ErrorMessages.InvalidEmailAddress, validateTrigger: 'onBlur' },
        ]}
        style={{ marginBottom: 32 }}
      >
        <Input type="email" disabled={isDisabled} size="large" />
      </Form.Item>
    </FormItemContainer>
  )
}
