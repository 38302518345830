import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { RulAi, RulAiVariableName, useStoreUrlVariables } from 'rulAi'
import styled from 'styled-components'

import { DISABLE_CHATBOT_IN_ROUTES } from 'components/app/paths'
import { PrimaryLogo } from 'components/common/primary-logo'
import { JustChildren } from 'components/common/react-types'
import { device } from 'components/constants/sizes'

const StyledPageContentWrapper = styled.div`
  @media only screen and ${device.small} {
    margin: 60px 20px;
    width: auto;
  }
  @media only screen and ${device.medium} {
    margin: 60px 60px;
    width: auto;
  }
  @media only screen and ${device.large} {
    margin: 80px auto;
    width: 960px;
  }
`

/**
 * Outermost container for all our paddle.net v2 web pages. It solves some common styling plus
 * functionality that should be available in any route, like rul.ai parameter filling through
 * URL params.
 */
export function BasePage(props: JustChildren): JSX.Element {
  useStoreUrlVariables()
  const location = useLocation()

  useEffect(() => {
    RulAi.setVariable(RulAiVariableName.SourcePath, location.pathname)

    if (DISABLE_CHATBOT_IN_ROUTES.includes(location.pathname)) {
      return RulAi.hide()
    }

    return RulAi.show()
  }, [location])

  return (
    <StyledPageContentWrapper>
      <PrimaryLogo />
      {props.children}
    </StyledPageContentWrapper>
  )
}
