import { Navigate, useLocation } from 'react-router-dom'

declare global {
  interface Window {
    _rulai?: any
  }
}

export const ContactPageRedirect = () => {
  const { search } = useLocation()
  return (
    <Navigate
      to={{
        pathname: '/',
        search,
      }}
    />
  )
}
