import styled from 'styled-components'

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
`

export const HorizontalStack = styled.div`
  display: flex;
  flex-direction: row;
`
