import { useStoreUrlVariables } from 'rulAi'
import styled from 'styled-components'

import { colors } from 'components/constants/colors'
import PaddleLogo from 'components/pages/contact-page/logo-primary.svg'
import PaddleBot from 'components/pages/contact-page/paddle-bot.png'

const PaddleBotInfoContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 9px;
`

const PaddleBotImageContainer = styled.div`
  margin-top: -50px;
  margin-left: -315px;
  @media only screen and (max-width: 650px) {
    margin-left: -285px;
  }
`

const PaddleBotInfo = styled.div`
  background: ${colors.uiAmber[200]};
  border-radius: 10px;
  width: 247px;
  color: ${colors.uiBlack[950]};
  padding: 20px 20px 20px 49px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors.uiBlack[950]};

  @media only screen and (max-width: 650px) {
    width: 242px;
    padding: 16px 15px 16px 53px;
  }
`

const PaddleBotInfoTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
`

const Heading = styled.div`
  color: ${colors.uiBlack[950]};
  font-weight: 500;
`

const InfoContainer = styled.div`
  border-top: 1px solid ${colors.padBlue[200]};
  padding-top: 16px;
  padding-bottom: 24px;
  & p:nth-child(2) {
    margin-bottom: 10px;
  }
`

const StyledLink = styled.a`
  color: ${colors.uiBlack[950]};
  font-size: 12px;
`

const LogoContainer = styled.div`
  display: block;
  padding-bottom: 40px;
  height: 45px;
`

const Logo = styled.img`
  width: 100px;
`

const PaddleBotImage = styled.img`
  width: 102px;
  height: 102px;
  @media only screen and (max-width: 650px) {
    width: 80px;
    height: 80px;
  }
`

const StyledButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
`

const WhiteStyledButton = styled(StyledButton)`
  font-weight: 500;
  color: ${colors.uiBlack[950]};
`

const BlueStyledButton = styled(StyledButton)`
  color: ${colors.uiBlack[950]};
`

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`

const Inner = styled.div`
  min-width: 340px;
  max-width: 580px;
  margin: auto;
  padding-top: 40px;
  @media only screen and (max-width: 650px) {
    padding: 30px;
  }
`

export const ContactPage = () => {
  useStoreUrlVariables()

  return (
    <Wrapper>
      <Inner>
        <div>
          <LogoContainer>
            <Logo src={PaddleLogo} alt="Paddle.net" title="Paddle.net" />
          </LogoContainer>

          <PaddleBotInfoContainer>
            <PaddleBotInfo>
              <PaddleBotInfoTitle>Hi there! I am Kino,</PaddleBotInfoTitle>I can help you with any question you have.
              Talk to me by clicking{' '}
              <WhiteStyledButton onClick={() => window._rulai_widget.popin()}>here</WhiteStyledButton>.
            </PaddleBotInfo>
            <PaddleBotImageContainer>
              <PaddleBotImage src={PaddleBot} alt="PaddleBot" />
            </PaddleBotImageContainer>
          </PaddleBotInfoContainer>

          <InfoContainer>
            <Heading>What is Paddle?</Heading>
            <p>Paddle sells software and digital products from thousands of software companies.</p>
            <p>
              If you do not recognize a charge and would like a receipt, or suspect that a charge is fraudulent we can
              help identify, investigate and quickly resolve the issue. Talk to us{' '}
              <BlueStyledButton onClick={() => window._rulai_widget.popin()}>here</BlueStyledButton>.
            </p>
          </InfoContainer>

          <StyledLink href="https://www.paddle.com/about/why-has-paddle-charged-me" target="_blank">
            More about Paddle
          </StyledLink>
        </div>
      </Inner>
    </Wrapper>
  )
}
