import { Form, Select } from 'antd'

import { FormItemContainer } from 'components/common/input/form-item-container'
import { SelectOption, StyledSelectContainer } from 'components/common/input/select'
import { PaymentMethods } from 'components/pages/find-purchase-page/constants'
import { ErrorMessages, FieldNames, StaticText } from 'components/pages/find-purchase-page/payment-form/constants'

export interface KoreanCardBrandFormItemProps {
  brands: Array<SelectOption>
  isDisabled: boolean
}

export function KoreanCardBrandFormItem({ brands, isDisabled }: KoreanCardBrandFormItemProps): JSX.Element {
  const form = Form.useFormInstance()
  const paymentMethod = Form.useWatch([FieldNames.PaymentMethod], form)

  const isKoreanCardMethod = paymentMethod === PaymentMethods.SouthKorea
  const containerStyle = isKoreanCardMethod ? {} : { display: 'none' }

  return (
    <FormItemContainer header={StaticText.KoreanCardBrandHeader} isRequired style={containerStyle}>
      <StyledSelectContainer>
        <Form.Item
          name={FieldNames.KoreanCardBrand}
          rules={[{ required: isKoreanCardMethod, message: ErrorMessages.NoCardBrand }]}
          style={{ marginBottom: 0 }}
        >
          <Select
            options={brands}
            disabled={isDisabled}
            placeholder={StaticText.CardBrandPlaceholder}
            size="large"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </StyledSelectContainer>
    </FormItemContainer>
  )
}
