import { ReactElement } from 'react'
import styled from 'styled-components'

import { newPalette } from 'components/constants/colors'

const StyledTransactionContainer = styled.div<StyledTransactionContainerProps>`
  padding: 4px;
  display: inline-flex;
  column-gap: 8px;
  background: ${(props) => props.background + newPalette.alpha[10]};
  transition: background 300ms ease-in;
  border-radius: 4px;
  max-width: max-content;
`

const StyledIconWithSmallTextContainer = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: start;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color};
`

interface StyledTransactionContainerProps {
  background: string
}

export interface GenericStatusProps {
  text: string
  icon: ReactElement
  textColor: string
  background: string
}

export interface SharedStatusProps {
  text: string
}

export function GenericStatus(props: GenericStatusProps): JSX.Element {
  return (
    <StyledTransactionContainer background={props.background}>
      <StyledIconWithSmallTextContainer color={props.textColor}>
        {props.icon}
        {props.text}
      </StyledIconWithSmallTextContainer>
    </StyledTransactionContainer>
  )
}
