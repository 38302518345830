import styled from 'styled-components'

import { newPalette } from 'components/constants/colors'

export const StyledDatePickerContainer = styled.div`
  /* Center the date table. */
  & .ant-picker-body {
    align-self: center;
  }

  /* Color of the displayed dates not in the current month. */
  & .ant-picker-cell:not(.ant-picker-cell-in-view) {
    color: ${newPalette.gray[600]};
  }

  /* Color of the selected date. */
  & .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: ${newPalette.primary.black};
  }

  /* Background color of disabled dates. */
  & .ant-picker-cell-disabled::before {
    background: ${newPalette.blackAlpha[20]};
  }

  /* Placeholder style. */
  & .ant-picker-input > input::placeholder {
    opacity: 0.25;
  }
`
