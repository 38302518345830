import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useToast } from 'common/toast/hook'
import { FetchError } from 'queries'
import { isAPIErr } from 'queries/apierr'
import { INVALID_CLOUDFLARE_TURNSTILE_TOKEN } from 'queries/common-errors'
import { fetchResource } from 'queries/fetch-resource'
import { postAuthLinkURL } from 'queries/routes'
import { APIErrCodeBadRequest, APIErrCodeInvalidField, APIErrResponse } from 'types/apierr'

export const POST_AUTH_LINK_ERROR = 'Email failed to send, try again'
export const POST_AUTH_LINK_INVALID_EMAIL_ERROR = 'Email is invalid, please re-enter it'

export interface PostAuthLinkVariables {
  email?: string
  customerID?: string
  cfTurnstileToken: string
}

export async function postAuthLink({ email, customerID, cfTurnstileToken }: PostAuthLinkVariables): Promise<boolean> {
  if (cfTurnstileToken === '') {
    throw new FetchError(INVALID_CLOUDFLARE_TURNSTILE_TOKEN)
  }

  const response = await fetchResource(postAuthLinkURL(), {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      customer_id: customerID,
    }),
    headers: {
      'Paddle-Cftt': cfTurnstileToken,
    },
  })

  if (!response.ok) {
    const { status, statusText } = response
    const cause = await response.json()

    if (!isAPIErr(cause)) {
      throw new FetchError(POST_AUTH_LINK_ERROR, { cause }, status, statusText)
    }

    const apiErr = cause as APIErrResponse

    let renderMessage = POST_AUTH_LINK_ERROR

    if (
      (apiErr.error!.code === APIErrCodeInvalidField && apiErr.error!.detail === 'invalid email') ||
      (apiErr.error!.code === APIErrCodeBadRequest &&
        apiErr.error!.errors &&
        apiErr.error!.errors.length &&
        apiErr.error!.errors[0].field === 'email')
    ) {
      renderMessage = POST_AUTH_LINK_INVALID_EMAIL_ERROR
    }

    throw new FetchError(renderMessage, { cause }, status, statusText)
  }

  return true
}

export const usePostAuthLink = (options?: UseMutationOptions<boolean, FetchError, PostAuthLinkVariables>) => {
  const toast = useToast()

  return useMutation<boolean, FetchError, PostAuthLinkVariables>(postAuthLink, {
    ...options,
    onError: (error) => toast.error(error.message),
  })
}
