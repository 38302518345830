import { isNil } from 'common/checks'
import { isBillingMagicLinkURL, isClassicMagicLinkURL } from 'common/url'

const getSupportSource = () => {
  const searchParams = new URLSearchParams(window.location.search)

  if (isClassicMagicLinkURL(searchParams)) {
    return 'classic-support-url'
  } else if (isBillingMagicLinkURL(searchParams)) {
    return 'billing-support-url'
  }

  return 'direct-traffic'
}

export const getLaunchDarklyContext = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const version = searchParams.get('netVersion')

  return {
    kind: 'multi',
    user: {
      kind: 'user',
      anonymous: true,
    },
    'support-source': {
      key: getSupportSource(),
    },
    route: {
      key: window.location.pathname,
    },
    ...(!isNil(version) && { version: { key: version } }),
  }
}
