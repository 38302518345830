import { isNil } from 'common/checks'
import { useEffect, useRef } from 'react'

export function useTimeout(callback: () => void, delay?: number | null) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (isNil(delay)) {
      return
    }

    const id = setTimeout(() => callbackRef.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}
