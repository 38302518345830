import { decodeToken } from 'common/token'
import { fetchResource } from 'queries/fetch-resource'

import { PATHS } from 'components/app/paths'

function checkTokenExpiry(token: string | null) {
  const decodedToken = decodeToken(token)

  if (decodedToken?.exp && decodedToken.exp <= new Date().getTime() / 1000) {
    window.location.href = PATHS.EXPIRED_TOKEN
  }
}

export async function fetchWithAuth(resource: RequestInfo | URL, token: string | null, config?: RequestInit) {
  checkTokenExpiry(token)

  const headers = Object.assign(
    {},
    {
      Authorization: `Bearer ${token}`,
    },
    config?.headers,
  )

  config = {
    ...(config ?? {}),
    headers,
  }

  return await fetchResource(resource, config)
}
