import * as React from 'react'
import styled, { keyframes } from 'styled-components'

import LargeYellowSpinnerSVG from 'components/images/spinners/large-yellow.svg'
import SmallBlackSpinnerSVG from 'components/images/spinners/small-black.svg'
import SmallGraySpinnerSVG from 'components/images/spinners/small-gray.svg'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledSpinnerContainer = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  animation: ${rotate} 1s linear infinite;
`

const LargeStyledSpinnerContainer = styled(StyledSpinnerContainer)`
  width: 40px;
  height: 40px;
`

export function YellowLoadingSpinner(): JSX.Element {
  return (
    <LargeStyledSpinnerContainer>
      <img src={LargeYellowSpinnerSVG} alt="large-yellow-spinner" />
    </LargeStyledSpinnerContainer>
  )
}

export function PrimaryLoadingSpinner(): JSX.Element {
  return (
    <StyledSpinnerContainer>
      <img src={SmallBlackSpinnerSVG} alt="small-black-spinner" />
    </StyledSpinnerContainer>
  )
}

export function SecondaryLoadingSpinner(): JSX.Element {
  return (
    <StyledSpinnerContainer>
      <img src={SmallGraySpinnerSVG} alt="small-gray-spinner" />
    </StyledSpinnerContainer>
  )
}
