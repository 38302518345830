import { Form, Input, Select, Space } from 'antd'
import { CurrencyOption } from 'common/currency'
import { ChangeEvent, useMemo } from 'react'
import styled from 'styled-components'

import { FormItemContainer } from 'components/common/input/form-item-container'
import { StyledSelectContainer } from 'components/common/input/select'
import { newPalette } from 'components/constants/colors'
import { PaymentMethods } from 'components/pages/find-purchase-page/constants'
import { ErrorMessages, FieldNames, StaticText } from 'components/pages/find-purchase-page/payment-form/constants'

const StyledOptionContainer = styled.div`
  display: flex;
  column-gap: 50px;
  justify-content: space-between;
`

function renderSelectCurrencyOption(currencyOption: CurrencyOption): JSX.Element {
  const { code, symbol, name } = currencyOption
  const fullName = `${name} (${code})`

  return (
    <Select.Option key={code}>
      <StyledOptionContainer>
        <span>{symbol}</span>
        <span>{fullName}</span>
      </StyledOptionContainer>
    </Select.Option>
  )
}

export interface AmountPaidFormItemProps {
  currencies: Array<CurrencyOption>
  isDisabled: boolean
}

export function AmountPaidFormItem({ currencies, isDisabled }: AmountPaidFormItemProps): JSX.Element {
  const form = Form.useFormInstance()
  const currencyCode = Form.useWatch([FieldNames.CurrencyCode], form)
  const paymentMethod = Form.useWatch([FieldNames.PaymentMethod], form)

  const currencySymbols = useMemo(() => {
    const currencySymbols = {}
    currencies.forEach(({ code, symbol }) => (currencySymbols[code] = symbol))
    return currencySymbols
  }, [currencies])
  const symbolPrefix = <div style={{ color: newPalette.whiteAlpha[95] }}>{currencySymbols[currencyCode]}</div>

  const isCardMethod = paymentMethod === PaymentMethods.Card
  const maybeNotZero = isCardMethod ? undefined : /^(?!0+(\.0*)?$)/

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target
    const nonNegative = /^\d+(\.\d*)?$/
    return nonNegative.test(inputValue) || inputValue === '' ? inputValue : form.getFieldValue(FieldNames.PaidAmount)
  }

  return (
    <FormItemContainer header={StaticText.AmountPaidHeader} isRequired>
      <Space.Compact size="large" style={{ width: '100%' }}>
        <Form.Item
          name={FieldNames.PaidAmount}
          getValueFromEvent={handleChange}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            { required: true, message: ErrorMessages.NoPaidAmount },
            { pattern: maybeNotZero, message: ErrorMessages.ZeroPaidAmount, validateTrigger: 'onBlur' },
          ]}
          dependencies={[FieldNames.PaymentMethod]}
          style={{ width: '100%', marginBottom: 0 }}
        >
          <Input prefix={symbolPrefix} disabled={isDisabled} />
        </Form.Item>
        <StyledSelectContainer>
          <Form.Item name={FieldNames.CurrencyCode} noStyle>
            <Select
              popupMatchSelectWidth={false}
              placement="bottomRight"
              optionLabelProp="code"
              disabled={isDisabled}
              style={{ width: 90 }}
            >
              {currencies.map(renderSelectCurrencyOption)}
            </Select>
          </Form.Item>
        </StyledSelectContainer>
      </Space.Compact>
    </FormItemContainer>
  )
}
