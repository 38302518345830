import styled from 'styled-components'

import { device } from 'components/constants/sizes'

export const StyledGridContainer = styled.div({
  display: 'grid',
  columnGap: 20,
  [`@media only screen and ${device.small}`]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  [`@media only screen and ${device.medium}, ${device.large}`]: {
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
})
