export const StaticText = {
  AmountPaidHeader: 'Amount paid',
  PaymentDateHeader: 'Payment date',
  PaymentMethodHeader: 'Payment method',
  KoreanCardBrandHeader: 'Card Brand',
  CardDigitsHeader: 'Last 4 digits of card',
  SubmitLabel: 'Get help',
  CardBrandPlaceholder: 'Please select your card brand',
} as const

export const ErrorMessages = {
  NoPaidAmount: 'The paid amount is required',
  ZeroPaidAmount: 'Amount must be positive',
  NoPaymentDate: 'The payment date is required',
  NoLastCardDigits: 'The last 4 digits of card are required',
  TooFewLastCardDigits: 'Please enter the last 4 digits of card',
  NoCardBrand: 'Please select the card brand',
} as const

export const FieldNames = {
  PaidAmount: 'paidAmount',
  CurrencyCode: 'currencyCode',
  PaymentDate: 'paymentDate',
  PaymentMethod: 'paymentMethod',
  KoreanCardBrand: 'cardBrand',
  LastCardDigits: 'lastCardDigits',
  TurnstileToken: 'turnstileToken',
} as const
