import { Form } from 'antd'
import { useState } from 'react'

import { TurnstileLoader } from 'components/common/turnstile/loader'
import { TurnstileWidget } from 'components/common/turnstile/widget'

const ERROR_MESSAGES = {
  NO_TURNSTILE_TOKEN: 'Please verify that you are human',
}

interface TurnstileChallengeProps {
  action: string
  value?: string
  shouldShow: (show: boolean) => void
  onChange?: (value: string) => void
}

function TurnstileChallenge({ action, shouldShow, onChange }: TurnstileChallengeProps): JSX.Element {
  return <TurnstileWidget action={action} getTokenCallback={onChange!} shouldShow={shouldShow} />
}

export interface TurnstileFormItemProps {
  fieldName: string
  action: string
}

export function TurnstileFormItem({ fieldName, action }: TurnstileFormItemProps): JSX.Element {
  const form = Form.useFormInstance()
  const token = Form.useWatch(fieldName, form)
  const [showChallenge, setShowChallenge] = useState(false)
  const isLoaderVisible = token === '' && !showChallenge

  return (
    <>
      <TurnstileLoader isVisible={isLoaderVisible} style={{ marginBottom: 32 }} />
      <Form.Item
        name={fieldName}
        hidden={!showChallenge}
        rules={[{ required: true, message: ERROR_MESSAGES.NO_TURNSTILE_TOKEN }]}
      >
        <TurnstileChallenge action={action} shouldShow={setShowChallenge} />
      </Form.Item>
    </>
  )
}
