import { getValueOrDefault } from 'common/checks'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { InputDesignConfig } from 'components/common/input/input-design-config'
import { Style } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'

const StyledHeader = styled.span`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

interface RequiredTagProps {
  isRequired?: boolean
}

function RequiredTag(props: RequiredTagProps): JSX.Element | null {
  const isRequired = getValueOrDefault(props.isRequired, false)

  if (!isRequired) {
    return null
  }

  return <StyledHeader color={newPalette.red[400]}> *</StyledHeader>
}

interface FormItemContainerProps {
  header: string
  children: ReactNode
  isRequired?: boolean
  style?: Style
}

export function FormItemContainer({ header, children, isRequired, style }: FormItemContainerProps): JSX.Element {
  return (
    <div style={style}>
      <div style={{ marginBottom: 4 }}>
        <StyledHeader color={newPalette.whiteAlpha[95]}>{header}</StyledHeader>
        <RequiredTag isRequired={isRequired} />
      </div>
      <InputDesignConfig>{children}</InputDesignConfig>
    </div>
  )
}
