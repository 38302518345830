export function isMagicLinkURL(searchParams: URLSearchParams) {
  return isClassicMagicLinkURL(searchParams) || isBillingMagicLinkURL(searchParams)
}

export function isClassicMagicLinkURL(searchParams: URLSearchParams) {
  return searchParams.get('h')
}

export function isBillingMagicLinkURL(searchParams: URLSearchParams) {
  return searchParams.get('token') && (searchParams.get('txn') || searchParams.get('sub'))
}
