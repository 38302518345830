import styled from 'styled-components'

import { newPalette } from 'components/constants/colors'

export const StyledWarningCardContainer = styled.div`
  background: ${newPalette.red[500] + newPalette.alpha[10]};
  border: 1px solid ${newPalette.red[500]};
  box-shadow: 0px 0px 29px ${newPalette.cardShadow};
  border-radius: 18px;
  padding: 24px;
`
