import { createGlobalStyle } from 'styled-components'

import { colors, newPalette } from 'components/constants/colors'
import background from 'components/images/background.svg'

const fontFamilyBase = '"Graphik", Helvetica, Arial, sans-serif'

export const LegacyGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Graphik';
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.eot');
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.woff') format('woff'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.ttf') format('truetype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.svg#GraphikRegular') format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.eot');
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.woff') format('woff'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.ttf') format('truetype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.svg#GraphikMedium') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.eot');
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.woff') format('woff'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.ttf') format('truetype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.svg#GraphikSemibold') format('svg');
    font-weight: 600;
    font-style: normal;
  }

  div, a {
    font-family: ${fontFamilyBase};
  }

  html, body, p, button {
    padding: 0;
    margin: 0;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    line-height: 24px;
    color: ${colors.padBlue[950]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    vertical-align: middle;
    border: 0;
  }
`

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Graphik';
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.eot');
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.woff') format('woff'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.ttf') format('truetype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.svg#GraphikRegular') format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.eot');
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.woff') format('woff'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.ttf') format('truetype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.svg#GraphikMedium') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.eot');
    src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.woff') format('woff'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.ttf') format('truetype'),
    url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.svg#GraphikSemibold') format('svg');
    font-weight: 600;
    font-style: normal;
  }

  div, a {
    font-family: ${fontFamilyBase};
  }

  html, body, p, button {
    padding: 0;
    margin: 0;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    line-height: 24px;
    color: ${newPalette.primary.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    vertical-align: middle;
    border: 0;
  }

  html {
    background-attachment: fixed;
    background-color: ${newPalette.primary.black};
    background-image: url(${background});
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
  }
`
