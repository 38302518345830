import styled, { css } from 'styled-components'

import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'

const baseFont = css`
  font-style: normal;
  font-weight: 500;
`

export const H2Font = css`
  font-size: 28px;
  line-height: 42px;
  ${baseFont}
`

export const H3Font = css`
  font-size: 20px;
  line-height: 30px;
  ${baseFont}
`

export const H4Font = css`
  font-size: 16px;
  line-height: 24px;
  ${baseFont}
`

export const StyledPageTitle = styled.div`
  color: ${newPalette.whiteAlpha[95]};
  @media only screen and ${device.small}, ${device.medium} {
    ${H3Font}
  }
  @media only screen and ${device.large} {
    ${H2Font}
  }
`

export const StyledSectionTitle = styled.div`
  color: ${newPalette.whiteAlpha[95]};
  ${H3Font}
`
