import { isNotNil } from 'common/checks'
import { isBillingMagicLinkURL } from 'common/url'
import { environmentConfig } from 'environments/config/config'
import { useGetToken } from 'hooks/useGetToken'
import { useGetOrders } from 'queries'
import { useEffect } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { RulAi, SourceScenario } from 'rulAi'
import styled from 'styled-components'
import { Order } from 'types/order'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { SecondaryButton } from 'components/common/buttons'
import { StyledCardContainer } from 'components/common/card'
import { YellowLoadingSpinner } from 'components/common/loading-spinner'
import { Style } from 'components/common/react-types'
import { LargeBodyCopyTextStyle } from 'components/common/text'
import { StyledPageTitle } from 'components/common/title'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import { PurchasesCardConstants, PurchasesPageConstants } from 'components/pages/purchases-page/constants'
import { PurchasesCard } from 'components/pages/purchases-page/purchases-card'

const StyledCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

const StyledFinalCardContainer = styled(StyledCardContainer)`
  display: flex;
  align-items: center;
  @media only screen and ${device.small} {
    padding: 20px;
  }
  @media only screen and ${device.medium}, ${device.large} {
    padding: 24px;
  }
`

const StyledSpinnerContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
`

const StyledFinalCardTextContainer = styled.div`
  color: ${newPalette.whiteAlpha['95']};
  margin-right: auto;
  ${LargeBodyCopyTextStyle}
`

const StyledFinalCardButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

function FinalCard(): JSX.Element {
  const navigate = useNavigate()

  return (
    <StyledFinalCardContainer>
      <StyledFinalCardTextContainer>{PurchasesCardConstants.FinalCardText}</StyledFinalCardTextContainer>
      <StyledFinalCardButtonsContainer>
        <SecondaryButton text={PurchasesCardConstants.TryAgainButton} onClick={() => navigate(PATHS.VERIFY_EMAIL)} />
        <SecondaryButton
          text={PurchasesCardConstants.GetHelpButton}
          onClick={() => RulAi.open(SourceScenario.LookingForSomethingElse)}
        />
      </StyledFinalCardButtonsContainer>
    </StyledFinalCardContainer>
  )
}

export function PurchasesPage(): JSX.Element {
  const pageTitleStyle: Style = {
    marginBottom: 40,
  }

  const { USE_STATIC_ORDER_DATA } = environmentConfig

  // Ideally we store the token somewhere so that it's automatically added to fetchWithAuth.
  // I discussed this with Colin and his preference is to keep it in memory. But that means
  // we will have to add the token to every link from overview page (for example the future
  // transaction details page)! Because these links can be opened in new tabs and otherwise
  // that will not work. His preference would be in-memory storage. If that is not going to
  // work, then session storage, then local storage, then cookies.
  const token = useGetToken()

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (isBillingMagicLinkURL(searchParams)) {
      RulAi.openForTransaction({
        transactionId: searchParams.get('txn'),
        subscriptionId: searchParams.get('sub'),
        token: searchParams.get('token'),
      })
    }
  }, [searchParams])

  const {
    data: orders,
    isInitialLoading,
    error: getOrdersError,
  } = useGetOrders(token, {
    enabled: !!token || USE_STATIC_ORDER_DATA,
  })

  if (token === '') {
    return <Navigate to={{ pathname: PATHS.ERROR }} replace={true} />
  }
  if (isNotNil(getOrdersError)) {
    throw getOrdersError
  }

  return (
    <BasePage>
      {orders && (
        <StyledPageTitle style={pageTitleStyle}>
          {orders.data.length === 1
            ? PurchasesPageConstants.PageTitleSingleProduct
            : PurchasesPageConstants.PageTitleMultipleProducts}
        </StyledPageTitle>
      )}
      <StyledCardListContainer>
        {isInitialLoading && (
          <StyledSpinnerContainer>
            <YellowLoadingSpinner />
          </StyledSpinnerContainer>
        )}

        {orders && orders.data.map((order: Order, index: number) => <PurchasesCard key={index} order={order} />)}
        {orders && <FinalCard />}
      </StyledCardListContainer>
    </BasePage>
  )
}
