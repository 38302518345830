import { TOAST_TYPES, ToastType } from 'common/toast/types'
import { useTimeout } from 'hooks/useTimeout'
import styled, { keyframes } from 'styled-components'

import { CloseButton } from 'components/common/close-button'
import { assertUnreachable } from 'components/common/errors'
import { MediumGenericIcon } from 'components/common/icon'
import { newPalette } from 'components/constants/colors'
import ConfirmationSvg from 'components/images/icons/confirmation-filled.svg'
import WarningSvg from 'components/images/icons/warning-filled.svg'

const slideUp = keyframes`
  from {
    bottom: -40px;
  }
  to {
    bottom: 24px;
  }
`

const slideDown = keyframes`
  from {
    bottom: 24px;
  }
  to {
    bottom: -40px;
  }
`

const StyledToastContainer = styled.div`
  position: fixed;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  max-width: 842px;
  column-gap: 20px;
  margin: auto;
  padding: 10px 14px;
  border-radius: 20px;
  background-color: ${newPalette.gray[800]};
  animation: ${slideUp} 0.3s cubic-bezier(0.54, 0.55, 0.4, 0.99),
    ${slideDown} 0.3s cubic-bezier(0.54, 0.55, 0.4, 0.99) 9.8s;
`

const StyledToastMessageContainer = styled.div`
  color: ${newPalette.whiteAlpha[95]};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function getToastTypeIcon(type: ToastType): JSX.Element {
  switch (type) {
    case TOAST_TYPES.SUCCESS: {
      return <MediumGenericIcon imgSource={ConfirmationSvg} imgTitle="Confirmation icon" />
    }
    case TOAST_TYPES.ERROR: {
      return <MediumGenericIcon imgSource={WarningSvg} imgTitle="Warning icon" />
    }
    default: {
      assertUnreachable('Invalid toast type: ', type)
    }
  }
}

export interface ToastProps {
  type: ToastType
  message: string
  close(): void
}

export function Toast({ type, message, close }: ToastProps): JSX.Element {
  useTimeout(close, 10000)

  const typeIcon = getToastTypeIcon(type)

  return (
    <StyledToastContainer>
      {typeIcon}
      <StyledToastMessageContainer>{message}</StyledToastMessageContainer>
      <CloseButton onClose={close} />
    </StyledToastContainer>
  )
}
