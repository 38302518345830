import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useToast } from 'common/toast/hook'
import { environmentConfig } from 'environments/config/config'
import { INVALID_CLOUDFLARE_TURNSTILE_TOKEN } from 'queries/common-errors'
import { FetchError } from 'queries/fetch-error'
import { fetchResource } from 'queries/fetch-resource'
import { postSearchCustomersURL } from 'queries/routes'
import staticResponse from 'testing/obfuscated-customer-data.json'

export const SEARCH_CUSTOMERS_ERROR = 'Failed to search for customers'

export interface SearchCustomersResponse {
  data: Array<Customer>
}

export interface Customer {
  id: string
  obfuscated_email: string
}

export interface PostSearchCustomersRequest {
  amount: number
  currency_code: string
  payment_date: string
  payment_method: string
  card_last_4?: string
  underlying_payment_method?: string
  cfTurnstileToken: string
}

export async function postSearchCustomers(req: PostSearchCustomersRequest): Promise<SearchCustomersResponse> {
  if (req.cfTurnstileToken === '') {
    throw new FetchError(INVALID_CLOUDFLARE_TURNSTILE_TOKEN)
  }

  const { USE_STATIC_OBFUSCATED_CUSTOMER_DATA } = environmentConfig

  if (USE_STATIC_OBFUSCATED_CUSTOMER_DATA) {
    return staticResponse as SearchCustomersResponse
  }

  const response = await fetchResource(postSearchCustomersURL(), {
    method: 'POST',
    body: JSON.stringify({
      payment_date: req.payment_date,
      amount: req.amount,
      card_last_4: req.card_last_4,
      payment_method: req.payment_method,
      underlying_payment_method: req.underlying_payment_method,
      currency_code: req.currency_code,
    }),
    headers: {
      'Paddle-Cftt': req.cfTurnstileToken,
    },
  })

  if (!response.ok) {
    const { status, statusText } = response
    const cause = await response.json()
    throw new FetchError(SEARCH_CUSTOMERS_ERROR, { cause }, status, statusText)
  }

  return response.json()
}

export const usePostSearchCustomers = (
  request?: PostSearchCustomersRequest,
  options?: UseQueryOptions<SearchCustomersResponse, FetchError>,
) => {
  const toast = useToast()

  return useQuery<SearchCustomersResponse, FetchError>(['customers', request], () => postSearchCustomers(request!), {
    ...options,
    enabled: !!request,
    onError: (error) => toast.error(error.message),
  })
}
