import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns'

export function DurationInDays(endDate: string): string {
  const interval = intervalToDuration({
    start: new Date(),
    end: parseISO(endDate),
  })

  return formatDuration(interval, {
    format: ['days'],
  })
}

export function FormatISODate(isoDate: string): string {
  // results in Nov 21, 2023
  return format(parseISO(isoDate), 'PP')
}
