import styled from 'styled-components'

import { newPalette } from 'components/constants/colors'

export const StyledSelectContainer = styled.div`
  /* Padding of the entire dropdown panel. */
  & .ant-select-dropdown {
    padding: 4px 0px;
  }

  /* Style of the selected option container. */
  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: ${newPalette.primary.yellow};

    border-left: 4px solid;
    padding-left: 8px;

    font-weight: normal;
  }

  /* Placeholder style. */
  & .ant-select-selection-placeholder {
    opacity: 0.25;
  }
`

export type SelectOption = {
  label: string
  value: string
}
