import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { environmentConfig } from 'environments/config/config'
import { FetchError } from 'queries'
import { fetchResource } from 'queries/fetch-resource'
import { getCurrenciesURL } from 'queries/routes'
import staticResponse from 'testing/supported-currencies.json'
import { Currency } from 'types/currency'

export const GET_CURRENCIES_ERROR = 'Failed to get currencies'

const ONE_HOUR = 60 * 1000 * 60

const DEFAULT_OPTIONS = {
  cacheTime: 24 * ONE_HOUR,
  staleTime: 12 * ONE_HOUR,
}

export interface CurrenciesResponse {
  data: Array<Currency>
}

export async function getCurrencies(): Promise<CurrenciesResponse> {
  const { USE_STATIC_CURRENCY_DATA } = environmentConfig

  if (USE_STATIC_CURRENCY_DATA) {
    return staticResponse as CurrenciesResponse
  }

  const response = await fetchResource(getCurrenciesURL(), { method: 'GET' })

  if (!response.ok) {
    const { status, statusText } = response
    const cause = await response.json()
    throw new FetchError(GET_CURRENCIES_ERROR, { cause }, status, statusText)
  }

  return response.json()
}

export const useGetCurrencies = (options?: UseQueryOptions<CurrenciesResponse, FetchError>) => {
  return useQuery<CurrenciesResponse, FetchError>(['currencies'], getCurrencies, {
    ...DEFAULT_OPTIONS,
    ...options,
  })
}
