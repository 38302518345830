import { Form, Select } from 'antd'

import { FormItemContainer } from 'components/common/input/form-item-container'
import { SelectOption, StyledSelectContainer } from 'components/common/input/select'
import { FieldNames, StaticText } from 'components/pages/find-purchase-page/payment-form/constants'

export interface PaymentMethodFormItemProps {
  paymentMethods: Array<SelectOption>
  isDisabled: boolean
}

export function PaymentMethodFormItem({ paymentMethods, isDisabled }: PaymentMethodFormItemProps): JSX.Element {
  return (
    <FormItemContainer header={StaticText.PaymentMethodHeader}>
      <StyledSelectContainer>
        <Form.Item name={FieldNames.PaymentMethod} noStyle>
          <Select options={paymentMethods} disabled={isDisabled} size="large" style={{ width: '100%' }} />
        </Form.Item>
      </StyledSelectContainer>
    </FormItemContainer>
  )
}
