import { useToast } from 'common/toast/hook'
import { usePostAuthLink } from 'queries'
import { POST_AUTH_LINK_ERROR } from 'queries/post-auth-link'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { StyledCardContainer } from 'components/common/card'
import { StyledGridContainer } from 'components/common/grid'
import { Style } from 'components/common/react-types'
import { StyledParagraph } from 'components/common/text'
import { StyledPageTitle } from 'components/common/title'
import { device } from 'components/constants/sizes'
import { VerificationEmailSentLocationState } from 'components/pages/verification-email-sent-page/verification-email-sent-page'
import { VerifyEmailPageConstants } from 'components/pages/verify-email-page/constants'
import { EmailForm } from 'components/pages/verify-email-page/email-form/form'

const StyledVerifyEmailGridContainer = styled(StyledGridContainer)`
  row-gap: 24px;
`

const responsiveWidth = css`
  @media only screen and ${device.small} {
    grid-column-end: span 6;
  }
  @media only screen and ${device.medium}, ${device.large} {
    grid-column-end: span 8;
  }
`

const StyledParagraphGridItem = styled(StyledParagraph)`
  ${responsiveWidth}
`

const StyledCardGridItem = styled(StyledCardContainer)`
  padding: 24px;
  ${responsiveWidth}
`

function EmailInputCard(): JSX.Element {
  const navigate = useNavigate()

  const { mutate: postAuthLink, isLoading: isRequestLoading, isError: didRequestFail } = usePostAuthLink()

  const toast = useToast()

  const handleVerifyEmail = (email: string, turnstileToken: string) => {
    const locationState: VerificationEmailSentLocationState = { previousLocation: PATHS.VERIFY_EMAIL, email }
    const navigateOptions = { state: locationState }

    postAuthLink(
      { email: email, cfTurnstileToken: turnstileToken },
      {
        onSuccess: (sent: boolean) => {
          if (sent) {
            navigate(PATHS.VERIFICATION_EMAIL_SENT, navigateOptions)
            toast.success('Email sent')
          } else {
            toast.error(POST_AUTH_LINK_ERROR)
          }
        },
      },
    )
  }

  return (
    <StyledCardGridItem>
      <EmailForm isRequestLoading={isRequestLoading} didRequestFail={didRequestFail} onSubmit={handleVerifyEmail} />
    </StyledCardGridItem>
  )
}

export function VerifyEmailPage(): JSX.Element {
  const pageTitleStyle: Style = {
    marginBottom: 24,
  }
  const firstParagraphStyle: Style = {
    marginBottom: 16,
  }

  return (
    <BasePage>
      <StyledPageTitle style={pageTitleStyle}>{VerifyEmailPageConstants.PageTitle}</StyledPageTitle>
      <StyledVerifyEmailGridContainer>
        <StyledParagraphGridItem style={firstParagraphStyle}>
          {VerifyEmailPageConstants.PageParagraph}
        </StyledParagraphGridItem>
        <EmailInputCard />
        <StyledParagraphGridItem>{VerifyEmailPageConstants.DisclaimerParagraph}</StyledParagraphGridItem>
      </StyledVerifyEmailGridContainer>
    </BasePage>
  )
}
