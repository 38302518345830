import { isNil, isNotNil } from 'common/checks'
import { useToast } from 'common/toast/hook'
import { usePostAuthLink } from 'queries'
import { PostAuthLinkVariables } from 'queries/post-auth-link'
import { useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { RulAi, SourceScenario } from 'rulAi'
import styled, { css } from 'styled-components'
import { Nullable } from 'types/nullable'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { SecondaryButton, StyledButtonFakeLink } from 'components/common/buttons'
import { StyledCardContainer } from 'components/common/card'
import { StyledGridContainer } from 'components/common/grid'
import { Style } from 'components/common/react-types'
import { StyledParagraph, StyledParagraphLargeBody } from 'components/common/text'
import { StyledPageTitle } from 'components/common/title'
import { TurnstileLoader } from 'components/common/turnstile/loader'
import { TurnstileWidget } from 'components/common/turnstile/widget'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import {
  CardConstants,
  VerificationEmailSentPageConstants,
} from 'components/pages/verification-email-sent-page/constants'

const StyledVerificationEmailSentGridContainer = styled(StyledGridContainer)`
  row-gap: 40px;
`

const responsiveWidth = css`
  @media only screen and ${device.small} {
    grid-column-end: span 6;
  }
  @media only screen and ${device.medium}, ${device.large} {
    grid-column-end: span 8;
  }
`

const StyledParagraphGray = styled(StyledParagraphLargeBody)`
  color: ${newPalette.gray[300]};
  ${responsiveWidth}
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 42px;
  gap: 20px;
`

const StyledCardGridItem = styled(StyledCardContainer)`
  padding: 24px;
  margin-bottom: 28px;
  ${responsiveWidth}
`

const StyledTurnstileContainer = styled.div`
  margin-top: 26px;
`

function VerificationEmailSentCard(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()

  const { mutate: postAuthLink, isLoading: isRequestLoading } = usePostAuthLink()

  const [showChallenge, setShowChallenge] = useState(false)
  const [shouldResendEmail, setShouldResendEmail] = useState(false)
  const [token, setToken] = useState('')

  const toast = useToast()

  const getTokenCallback = (token: string) => {
    setToken(token)

    if (token === '') {
      return
    }

    const email: string = location.state.email
    const customerID: Nullable<string> = location.state?.customerID ?? null
    const postAuthLinkVariables: PostAuthLinkVariables = isNotNil(customerID)
      ? { customerID, cfTurnstileToken: token }
      : { email, cfTurnstileToken: token }

    postAuthLink(postAuthLinkVariables, {
      onSuccess: (sent: boolean) => {
        if (sent) {
          toast.success('Email sent')
        } else {
          toast.error('Email failed to send, try again')
        }

        setShowChallenge(false)
        setTimeout(() => {
          setShouldResendEmail(false)
          setToken('')
        }, 5000)
      },
      onError: () => {
        navigate(PATHS.ERROR)
      },
    })
  }

  const isTurnstileLoaderVisible = shouldResendEmail && token === '' && !showChallenge
  const isTurnstileContainerVisible = (shouldResendEmail && token === '') || showChallenge

  return (
    <StyledCardGridItem>
      <StyledParagraph style={{ marginBottom: '1.43em' }}>{CardConstants.FirstParagraph}</StyledParagraph>
      <StyledParagraph>{CardConstants.SecondParagraph}</StyledParagraph>
      <StyledButtonsContainer>
        <SecondaryButton
          text={CardConstants.SendEmailAgainButton}
          onClick={() => setShouldResendEmail(true)}
          isLoading={isRequestLoading}
          isDisabled={shouldResendEmail && !isRequestLoading}
        />
        <SecondaryButton text={CardConstants.TryAnotherEmailButton} onClick={() => navigate(-1)} />
      </StyledButtonsContainer>
      {isTurnstileContainerVisible && (
        <StyledTurnstileContainer>
          <TurnstileLoader isVisible={isTurnstileLoaderVisible} />
          <TurnstileWidget
            action="resend-email"
            getTokenCallback={getTokenCallback}
            shouldShow={setShowChallenge}
            style={isTurnstileLoaderVisible ? { position: 'absolute' } : undefined}
          />
        </StyledTurnstileContainer>
      )}
    </StyledCardGridItem>
  )
}

interface LocationProps {
  previousLocation: string
  email: string
}

interface GetHelpLinkProps {
  previousLocation: string
  scenario: SourceScenario
}

function GetHelpOrAnotherWayLink(props: GetHelpLinkProps): JSX.Element {
  const navigate = useNavigate()
  const navigateOptions = { state: { previousLocation: PATHS.VERIFICATION_EMAIL_SENT } }

  if (props.previousLocation === PATHS.RECOGNIZE_EMAIL) {
    return (
      <StyledButtonFakeLink onClick={() => RulAi.open(props.scenario)}>
        {VerificationEmailSentPageConstants.GetHelpLink}
      </StyledButtonFakeLink>
    )
  }

  return (
    <StyledButtonFakeLink onClick={() => navigate(PATHS.FIND_PURCHASE, navigateOptions)}>
      {VerificationEmailSentPageConstants.TryAnotherWayLink}
    </StyledButtonFakeLink>
  )
}

function EmailSentParagraph(props: LocationProps): JSX.Element {
  if (props.previousLocation === PATHS.RECOGNIZE_EMAIL) {
    return (
      <StyledParagraphGray>
        {VerificationEmailSentPageConstants.IncompleteEmailParagraph + props.email}
      </StyledParagraphGray>
    )
  }

  return (
    <StyledParagraphGray>
      {VerificationEmailSentPageConstants.LocatedPurchaseParagraph + props.email}
    </StyledParagraphGray>
  )
}

export interface VerificationEmailSentLocationState {
  previousLocation: string
  email: string
  customerID?: string
}

export function VerificationEmailSentPage(): JSX.Element {
  const location = useLocation()

  const previousLocation: Nullable<string> = location.state?.previousLocation ?? null
  if (isNil(previousLocation)) {
    return <Navigate to={{ pathname: PATHS.VERIFY_EMAIL }} replace={true} />
  }

  const email: Nullable<string> = location.state?.email ?? null
  if (isNil(email)) {
    return <Navigate to={{ pathname: previousLocation }} replace={true} />
  }

  const pageTitleStyle: Style = { marginBottom: 24 }

  return (
    <BasePage>
      <StyledPageTitle style={pageTitleStyle}>{VerificationEmailSentPageConstants.PageTitle} </StyledPageTitle>
      <StyledVerificationEmailSentGridContainer>
        <EmailSentParagraph previousLocation={previousLocation} email={email} />
        <VerificationEmailSentCard />
      </StyledVerificationEmailSentGridContainer>
      <StyledParagraph>
        {VerificationEmailSentPageConstants.BottomParagraph}
        <GetHelpOrAnotherWayLink previousLocation={previousLocation} scenario={SourceScenario.StillNoEmail} />
      </StyledParagraph>
    </BasePage>
  )
}
