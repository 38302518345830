export const VerificationEmailSentPageConstants = {
  PageTitle: 'Verify your email',
  IncompleteEmailParagraph: 'We’ve sent an email to ',
  LocatedPurchaseParagraph: 'If we have located a purchase, you will shortly receive an email at ',
  BottomParagraph: 'Still no email? ',
  GetHelpLink: 'Get help',
  TryAnotherWayLink: 'Try another way',
} as const

export const CardConstants = {
  FirstParagraph: "To help keep your data secure, we need to verify that it's really you before we continue.",
  SecondParagraph: "If you didn't receive the email, check your junk folder or try another email address.",
  SendEmailAgainButton: 'Send email again',
  TryAnotherEmailButton: 'Try another email address',
  FailedEmailSendToast: 'Email failed to send, try again',
} as const
