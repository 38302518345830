export type RulAiVariable = {
  name: RulAiVariableName
  value: any
}

export enum RulAiVariableName {
  SourcePath = 'source.path',
  SourceScenario = 'source.scenario',
  EmailAddress = 'customer.email',
  // todo define json encoded value of payment details and check with @mosthafa the structure of this
  PaymentDetails = 'payment.search_details',
  Hash = 'email.hash',
  Token = 'email.token',
  EmailType = 'email.type',
  SubscriptionId = 'email.subscription_id',
  TransactionId = 'email.transaction_id',
}

// If we add a scenario we have to check with support-ops if they need to modify the chatbot to recognize this scenario!
export enum SourceScenario {
  TransactionResult = 'transaction-result',
  LookingForSomethingElse = 'escalate',
  NoResultsFromSearch = 'no-results-from-search',
  EmailNotRecognized = 'email-not-recognized',
  StillNoEmail = 'still-no-email',
}
