import styled from 'styled-components'

const StyledSmallIcon = styled.img`
  width: 12px;
  height: 12px;
`

const StyledMediumIcon = styled.img`
  width: 16px;
  height: 16px;
`

const StyledLargeIcon = styled.img`
  width: 20px;
  height: 20px;
`

interface GenericIconProps {
  imgSource: string
  imgTitle: string
}

export function SmallGenericIcon({ imgSource, imgTitle }: GenericIconProps): JSX.Element {
  return <StyledSmallIcon src={imgSource} title={imgTitle} aria-hidden alt="" draggable={false} />
}

export function MediumGenericIcon({ imgSource, imgTitle }: GenericIconProps): JSX.Element {
  return <StyledMediumIcon src={imgSource} title={imgTitle} aria-hidden alt="" draggable={false} />
}

export function LargeGenericIcon({ imgSource, imgTitle }: GenericIconProps): JSX.Element {
  return <StyledLargeIcon src={imgSource} title={imgTitle} aria-hidden alt="" draggable={false} />
}
