import styled from 'styled-components'

import { LargeGenericIcon } from 'components/common/icon'
import { ObjectValues } from 'components/common/react-types'
import { StyledTextSpan } from 'components/common/text'
import CancelSubscriptionSvg from 'components/images/icons/cancel-subscription.svg'
import HelpSvg from 'components/images/icons/help.svg'
import ProfileSvg from 'components/images/icons/profile.svg'
import ReceiptSvg from 'components/images/icons/receipt.svg'
import RefundSvg from 'components/images/icons/refund.svg'

const StyledIconWithTextContainer = styled.div`
  display: flex;
  column-gap: 12px;
  justify-content: start;
`

const IconComponentsTitles = {
  refund: 'Refund icon',
  help: 'Help icon',
  receipt: 'Receipt icon',
  profile: 'Profile icon',
  cancelSubscription: 'Cancel subscription icon',
}

const IconComponents = {
  refund: <LargeGenericIcon imgSource={RefundSvg} imgTitle={IconComponentsTitles.refund} />,
  help: <LargeGenericIcon imgSource={HelpSvg} imgTitle={IconComponentsTitles.help} />,
  receipt: <LargeGenericIcon imgSource={ReceiptSvg} imgTitle={IconComponentsTitles.receipt} />,
  profile: <LargeGenericIcon imgSource={ProfileSvg} imgTitle={IconComponentsTitles.profile} />,
  cancelSubscription: (
    <LargeGenericIcon imgSource={CancelSubscriptionSvg} imgTitle={IconComponentsTitles.cancelSubscription} />
  ),
}

export const IconKeys = {
  refund: 'refund',
  help: 'help',
  receipt: 'receipt',
  profile: 'profile',
  cancelSubscription: 'cancelSubscription',
} as const

type Icons = ObjectValues<typeof IconKeys>

interface IconWithTextProps {
  icon: Icons
  text: string
}

export function IconWithText(props: IconWithTextProps): JSX.Element {
  return (
    <StyledIconWithTextContainer>
      {IconComponents[props.icon]}
      <StyledTextSpan>{props.text}</StyledTextSpan>
    </StyledIconWithTextContainer>
  )
}
