import * as Sentry from '@sentry/react'
import { checkNotNil, isNotNil } from 'common/checks'
import { environmentConfig } from 'environments/config/config'
import { getLaunchDarklyContext } from 'launchDarkly'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { AppDispatcher } from 'components/dispatcher/AppDispatcher'

import reportWebVitals from './reportWebVitals'

;(async () => {
  const { LAUNCH_DARKLY_CLIENT_SIDE_ID, SENTRY_DSN, ENVIRONMENT, RELEASE } = environmentConfig
  const context = getLaunchDarklyContext()

  // TODO We may want to improve this if we start chaining Context Providers:
  const LaunchDarklyContextProvider = await asyncWithLDProvider({
    clientSideID: checkNotNil(LAUNCH_DARKLY_CLIENT_SIDE_ID),
    options: {
      bootstrap: 'localStorage',
    },
    context: context,
  })

  if (isNotNil(SENTRY_DSN) && SENTRY_DSN !== '') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: RELEASE,
    })
  }

  const root = createRoot(checkNotNil(document.getElementById('root')))
  root.render(
    <React.StrictMode>
      <LaunchDarklyContextProvider>
        <AppDispatcher />
      </LaunchDarklyContextProvider>
    </React.StrictMode>,
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
