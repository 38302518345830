import { APIErrResponse } from 'types/apierr'

export function isAPIErr(obj: any): obj is APIErrResponse {
  return (
    typeof obj.error === 'object' &&
    typeof obj.error.code === 'string' &&
    typeof obj.error.detail === 'string' &&
    typeof obj.error.type === 'string'
  )
}
