import { Form, Input } from 'antd'
import { ChangeEvent } from 'react'

import { FormItemContainer } from 'components/common/input/form-item-container'
import { PaymentMethods } from 'components/pages/find-purchase-page/constants'
import { ErrorMessages, FieldNames, StaticText } from 'components/pages/find-purchase-page/payment-form/constants'

export interface LastCardDigitsFormItemProps {
  isDisabled: boolean
}

export function LastCardDigitsFormItem({ isDisabled }: LastCardDigitsFormItemProps): JSX.Element {
  const form = Form.useFormInstance()
  const paymentMethod = Form.useWatch([FieldNames.PaymentMethod], form)

  const isCardMethod = paymentMethod === PaymentMethods.Card
  const containerStyle = isCardMethod ? {} : { display: 'none' }
  const requiredLen = isCardMethod ? 4 : undefined

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target
    const onlyDigits = /^\d+$/
    return onlyDigits.test(inputValue) || inputValue === '' ? inputValue : form.getFieldValue(FieldNames.LastCardDigits)
  }

  return (
    <FormItemContainer header={StaticText.CardDigitsHeader} isRequired style={containerStyle}>
      <Form.Item
        name={FieldNames.LastCardDigits}
        getValueFromEvent={handleChange}
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          { required: isCardMethod, message: ErrorMessages.NoLastCardDigits },
          { len: requiredLen, message: ErrorMessages.TooFewLastCardDigits, validateTrigger: 'onBlur' },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Input maxLength={4} disabled={isDisabled} size="large" />
      </Form.Item>
    </FormItemContainer>
  )
}
