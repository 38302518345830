import { isNil } from 'common/checks'
import { useToast } from 'common/toast/hook'
import { usePostAuthLink } from 'queries'
import { Customer } from 'queries/search-customers'
import { useState, Dispatch } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { RulAi, SourceScenario } from 'rulAi'
import styled from 'styled-components'
import { Nullable } from 'types/nullable'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { SecondaryButton, StyledButtonFakeLink } from 'components/common/buttons'
import { StyledCardContainer } from 'components/common/card'
import { StyledGridContainer } from 'components/common/grid'
import { LargeBodyCopyTextStyle, StyledParagraph } from 'components/common/text'
import { StyledPageTitle } from 'components/common/title'
import { TurnstileLoader } from 'components/common/turnstile/loader'
import { TurnstileWidget } from 'components/common/turnstile/widget'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import { RecognizeEmailConstants } from 'components/pages/recognize-email-page/constants'
import { VerificationEmailSentLocationState } from 'components/pages/verification-email-sent-page/verification-email-sent-page'

const StyledRecognizeEmailGridContainer = styled(StyledGridContainer)`
  margin-bottom: 28px;
  row-gap: 20px;
`

const StyledCardGridItem = styled(StyledCardContainer)`
  @media only screen and ${device.small} {
    grid-column-end: span 6;
    padding: 20px;
  }

  @media only screen and ${device.medium}, ${device.large} {
    grid-column-end: span 8;
    padding: 24px;
  },
`

const StyledFlexContainer = styled.div`
  @media only screen and ${device.small} {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 24px;
  }

  @media only screen and ${device.medium}, ${device.large} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  },
`

const StyledObfuscatedEmailContainer = styled.div`
  color: ${newPalette.whiteAlpha['95']};
  ${LargeBodyCopyTextStyle}
`

interface ObfuscatedEmailContainerProps {
  obfuscatedEmail: string
  customerID: string
  isButtonDisabled: boolean
  setAnyEmailSelected: Dispatch<boolean>
}

function ObfuscatedEmailContainer({
  obfuscatedEmail,
  customerID,
  isButtonDisabled,
  setAnyEmailSelected,
}: ObfuscatedEmailContainerProps): JSX.Element {
  const navigate = useNavigate()

  const locationState: VerificationEmailSentLocationState = {
    previousLocation: PATHS.FIND_PURCHASE,
    email: obfuscatedEmail,
    customerID,
  }
  const navigateOptions = { state: locationState }

  const { mutate: postAuthLink, isLoading: isRequestLoading } = usePostAuthLink()

  const [showChallenge, setShowChallenge] = useState(false)
  const [emailSelected, setEmailSelected] = useState(false)
  const [token, setToken] = useState('')

  const toast = useToast()

  const getTokenCallback = (token: string) => {
    setToken(token)

    if (token === '') {
      return
    }

    postAuthLink(
      { customerID, cfTurnstileToken: token },
      {
        onSuccess: (sent: boolean) => {
          if (sent) {
            navigate(PATHS.VERIFICATION_EMAIL_SENT, navigateOptions)
            toast.success('Email sent')
          } else {
            toast.error('Email failed to send, try again')
          }

          setShowChallenge(false)
          setEmailSelected(false)
          setTimeout(() => {
            setToken('')
            setAnyEmailSelected(false)
          }, 5000)
        },
        onError: () => {
          navigate(PATHS.ERROR)
        },
      },
    )
  }

  const isTurnstileLoaderVisible = emailSelected && token === '' && !showChallenge
  const isButtonVisible = !emailSelected || isRequestLoading

  return (
    <StyledCardGridItem>
      <StyledFlexContainer>
        <StyledObfuscatedEmailContainer>{obfuscatedEmail}</StyledObfuscatedEmailContainer>
        {isButtonVisible && (
          <SecondaryButton
            text={RecognizeEmailConstants.EmailButtonText}
            onClick={() => {
              setAnyEmailSelected(true)
              setEmailSelected(true)
            }}
            isLoading={isRequestLoading}
            isDisabled={isButtonDisabled && !isRequestLoading}
          />
        )}
        <>
          <TurnstileLoader isVisible={isTurnstileLoaderVisible} />
          {emailSelected && !isRequestLoading && (
            <TurnstileWidget
              action="recognize-email"
              getTokenCallback={getTokenCallback}
              shouldShow={setShowChallenge}
              style={isTurnstileLoaderVisible ? { position: 'absolute' } : undefined}
            />
          )}
        </>
      </StyledFlexContainer>
    </StyledCardGridItem>
  )
}

export function RecognizeEmailPage(): JSX.Element {
  const location = useLocation()

  const [isAnyEmailSelected, setIsAnyEmailSelected] = useState(false)

  const previousLocation: Nullable<string> = location.state?.previousLocation ?? null
  if (isNil(previousLocation)) {
    return <Navigate to={{ pathname: PATHS.FIND_PURCHASE }} replace={true} />
  }

  const customers: Nullable<Array<Customer>> = location.state?.customers ?? null
  if (isNil(customers)) {
    return <Navigate to={{ pathname: previousLocation }} replace={true} />
  }

  const PageTitle = getTitle(customers)

  const emailsComponents = customers.map((customer, index) => {
    return (
      <ObfuscatedEmailContainer
        key={index}
        obfuscatedEmail={customer.obfuscated_email}
        customerID={customer.id}
        setAnyEmailSelected={setIsAnyEmailSelected}
        isButtonDisabled={isAnyEmailSelected}
      />
    )
  })

  return (
    <BasePage>
      <StyledPageTitle style={{ marginBottom: 40 }}>{PageTitle} </StyledPageTitle>
      <StyledRecognizeEmailGridContainer>{emailsComponents}</StyledRecognizeEmailGridContainer>
      <StyledParagraph>
        {RecognizeEmailConstants.BottomParagraph}{' '}
        <StyledButtonFakeLink onClick={() => RulAi.open(SourceScenario.EmailNotRecognized)}>
          {RecognizeEmailConstants.GetHelpLink}
        </StyledButtonFakeLink>
      </StyledParagraph>
    </BasePage>
  )
}

function getTitle(customers: Customer[]): string {
  return customers.length > 1
    ? RecognizeEmailConstants.PageTitleMultipleResults
    : RecognizeEmailConstants.PageTitleSingleResult
}
