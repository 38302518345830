export const environmentConfig = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  RELEASE: process.env.REACT_APP_RELEASE,
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '60e1d9b351479b247fe36604',
  SENTRY_DSN: 'https://140533f4c8c7413c966d9d26d436a615@o522631.ingest.sentry.io/6141892',
  API_GATEWAY_URL: 'https://api.paddle.com',
  CF_TURNSTILE_SITE_KEY: '0x4AAAAAAALJUrIEmu1jQ4kl',
  USE_STATIC_ORDER_DATA: false,
  USE_STATIC_CURRENCY_DATA: false,
  USE_STATIC_OBFUSCATED_CUSTOMER_DATA: false,
}
