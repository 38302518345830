import { DatePicker, Form } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { StyledDatePickerContainer } from 'components/common/input/date-picker'
import { FormItemContainer } from 'components/common/input/form-item-container'
import { ErrorMessages, FieldNames, StaticText } from 'components/pages/find-purchase-page/payment-form/constants'

export interface PaymentDateFormItemProps {
  isDisabled: boolean
}

export function PaymentDateFormItem({ isDisabled }: PaymentDateFormItemProps): JSX.Element {
  const disableAfterToday = (current: Dayjs) => current > dayjs().endOf('day')

  return (
    <FormItemContainer header={StaticText.PaymentDateHeader} isRequired>
      <StyledDatePickerContainer>
        <Form.Item
          name={FieldNames.PaymentDate}
          validateTrigger={['onChange', 'onBlur']}
          rules={[{ required: true, message: ErrorMessages.NoPaymentDate }]}
          style={{ marginBottom: 0 }}
        >
          <DatePicker
            disabledDate={disableAfterToday}
            placeholder="YYYY-MM-DD"
            disabled={isDisabled}
            size="large"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </StyledDatePickerContainer>
    </FormItemContainer>
  )
}
