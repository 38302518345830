import { Popover } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { CloseButton } from 'components/common/close-button'
import { HorizontalStack, VerticalStack } from 'components/common/flex'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import { TransactionStatus, TransactionStatusTypeKeys } from 'components/pages/purchases-page/transaction-status'

interface StoryPointItemProps {
  isLast: boolean
}

const StyledStoryPointLine = styled.div<StoryPointItemProps>`
  border: 1px solid ${newPalette.whiteAlpha[40]};
  margin: 2px 10px;
  visibility: ${(props) => (props.isLast ? 'hidden' : 'visible')};
`

const StyledStoryPointText = styled.div<StoryPointItemProps>`
  color: ${newPalette.gray[400]};
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: ${(props) => (props.isLast ? '0px' : '36px')};
`

const StyledPopoverContainer = styled.div`
  .ant-popover {
    width: 280px;

    @media only screen and ${device.small} {
      width: 220px;
    }
  }
`

export interface TransactionStoryPoint {
  type: TransactionStatusTypeKeys
  description: string
}

interface TransactionStoryPointContainerProps {
  storyPoint: TransactionStoryPoint
  isLast: boolean
}

function TransactionStoryPointContainer(props: TransactionStoryPointContainerProps): JSX.Element {
  const { storyPoint, isLast } = props
  const { type, description } = storyPoint

  return (
    <VerticalStack>
      <TransactionStatus type={type} />
      <HorizontalStack>
        <StyledStoryPointLine isLast={isLast} />
        <StyledStoryPointText isLast={isLast}>{description}</StyledStoryPointText>
      </HorizontalStack>
    </VerticalStack>
  )
}

interface TransactionTimelineContentProps {
  storyPoints: TransactionStoryPoint[]
  onClose(): void
}

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  line-height: 0px;
`

function TransactionTimelineContent(props: TransactionTimelineContentProps): JSX.Element {
  const { storyPoints, onClose } = props

  const items = storyPoints.map((storyPoint, index) => {
    const isLast = index === storyPoints.length - 1

    return <TransactionStoryPointContainer storyPoint={storyPoint} isLast={isLast} />
  })

  return (
    <VerticalStack>
      <StyledCloseButtonContainer>
        <CloseButton onClose={onClose} />
      </StyledCloseButtonContainer>
      {items}
    </VerticalStack>
  )
}

interface TransactionTimelineProps {
  storyPoints: TransactionStoryPoint[]
}

export function TransactionTimeline(props: TransactionTimelineProps): JSX.Element | null {
  const { storyPoints } = props

  const [open, setOpen] = useState(false)

  if (storyPoints.length === 0) {
    return null
  }

  const statusType = storyPoints[0].type
  const status = <TransactionStatus type={statusType} />

  // Placement and alignment of the popup panel. It needs to be set manually in order to perfectly align the trigger's
  // status with the status of the most recent transaction story point.
  const placement = 'bottomLeft'
  const alignment = { offset: [-12, -36] }

  return <div>{status}</div>
}
