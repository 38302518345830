import { environmentConfig } from 'environments/config/config'

const { API_GATEWAY_URL } = environmentConfig

export const getCurrenciesURL = () => {
  return `${API_GATEWAY_URL}/currencies`
}

export const getOrdersURL = () => {
  return `${API_GATEWAY_URL}/support/orders`
}

export const postAuthLinkURL = () => {
  return `${API_GATEWAY_URL}/support/auth-link`
}

export const postSearchCustomersURL = () => {
  return `${API_GATEWAY_URL}/support/search/customers`
}
