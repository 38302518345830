export const colors = {
  transparent: 'transparent',
  padBlue: {
    950: '#0E1414',
    900: '#191D26',
    800: '#2B3242',
    600: '#36476C',
    500: '#45567C',
    400: '#73809C',
    300: '#A2ABBD',
    200: '#D2D4DE',
    100: '#EBECF0',
    50: '#F5F5F8',
  },
  padGrey: {
    500: '#7A88A7',
    400: '#9CA6BD',
    300: '#BDC4D4',
    200: '#DEE0E8',
    50: '#FAFAFC',
  },
  elecBlue: {
    700: '#0970B9',
    600: '#0886DE',
    500: '#0096FF',
    400: '#3FB0FF',
    300: '#7FC9FF',
    200: '#C0E6FF',
  },
  skyBlue: {
    500: '#CFE9FF',
    400: '#DBF1FF',
    300: '#E7F5FF',
    200: '#F1FAFF',
    100: '#F8FCFF',
  },
  uiRed: {
    700: '#D82A46',
    600: '#E73F49',
    500: '#F6434E',
    400: '#FF7D84',
    300: '#FC9AA0',
    100: '#FFEBED',
  },
  uiAmber: {
    700: '#BF911F',
    600: '#DDAC33',
    500: '#F0BB37',
    400: '#F5D582',
    200: '#FDDD35',
    100: '#FFF6E0',
  },
  uiGreen: {
    700: '#198C27',
    600: '#3CAC4A',
    500: '#41BB50',
    400: '#68E377',
    100: '#E3FCE6',
  },
  padPurple: {
    500: '#673F89',
    400: '#8B70A7',
    300: '#B39FC4',
    200: '#D9CFE2',
  },
  elecPurple: {
    500: '#A568F2',
    400: '#BC8FF5',
    300: '#D2B4F9',
    200: '#E8DAFC',
  },
  padGreen: {
    700: '#0dd490',
    600: '#2CE5A6',
    500: '#4FF4BC',
    400: '#7BF7CD',
    300: '#A6FADD',
    200: '#D3FCEE',
  },
  padRed: {
    500: '#F44F7F',
    400: '#FF7EA8',
    300: '#FFA8C4',
    200: '#FFD4E2',
  },
  blackGrey: {
    200: '#E2E2E2',
    400: '#8A8A8A',
    800: '#323232',
    900: '#1E1E1E',
  },
  padBladGrey: {
    400: '#BBBBBB',
  },
  uiBlack: '#2F2F2F',
  uiWhite: '#FFFFFF',
  black: '#000000',
}

// TODO Add missing colors
// https://www.figma.com/file/uAitPxnzymT7N4KRecgLnf/DevDocs-2.0?node-id=1426-25470&t=yv9vWLPBsOHano66-0
export const newPalette = {
  primary: {
    black: '#0E1414',
    white: '#FCFCFC',
    yellow: '#FDDD35',
  },
  whiteAlpha: {
    95: 'rgba(252, 252, 252, 0.95)',
    80: 'rgba(252, 252, 252, 0.8)',
    60: 'rgba(252, 252, 252, 0.6)',
    40: 'rgba(252, 252, 252, 0.4)',
    20: 'rgba(252, 252, 252, 0.2)',
    10: 'rgba(252, 252, 252, 0.1)',
    5: 'rgba(252, 252, 252, 0.05)',
    2: 'rgba(252, 252, 252, 0.02)',
  },
  blackAlpha: {
    95: 'rgba(14, 20, 20, 0.95)',
    80: 'rgba(14, 20, 20, 0.8)',
    60: 'rgba(14, 20, 20, 0.6)',
    40: 'rgba(14, 20, 20, 0.4)',
    20: 'rgba(14, 20, 20, 0.2)',
    10: 'rgba(14, 20, 20, 0.1)',
    5: 'rgba(14, 20, 20, 0.05)',
    2: 'rgba(14, 20, 20, 0.02)',
  },
  red: {
    900: '#B22930',
    800: '#C0343B',
    700: '#CD3A42',
    600: '#E04249',
    500: '#EF4B4B',
    400: '#E95A61',
    300: '#DE787F',
    200: '#E99DA3',
    100: '#FACFD6',
    50: '#FDECF0',
  },
  gray: {
    900: '#202323',
    800: '#404444',
    700: '#5F6363',
    600: '#737777',
    500: '#9CA0A0',
    400: '#BBBFBF',
    300: '#DEE2E2',
    200: '#EBF0F0',
    100: '#F2F6F6',
    50: '#F7FBFB',
  },
  purple: {
    900: '#3600BD',
    800: '#5000CB',
    700: '#5F00D3',
    600: '#6F08DC',
    500: '#7A11E2',
    400: '#9141EF',
    300: '#A568F2',
    200: '#C096F5',
    100: '#D9C1F8',
    50: '#F1E6FD',
  },
  green: {
    900: '#004E33',
    800: '#006A4F',
    700: '#007A5D',
    600: '#008A6D',
    500: '#00977A',
    400: '#00A88D',
    300: '#09B8A0',
    200: '#6CCDBC',
    100: '#A8E0D6',
    50: '#DCF3EF',
  },
  blue: {
    900: '#3448AE',
    800: '#3A68CE',
    700: '#3E79E1',
    600: '#418BF5',
    500: '#429BFF',
    400: '#54A9FF',
    300: '#6FB9FF',
    200: '#97CDFF',
    100: '#BFE0FF',
    50: '#E5F3FF',
  },
  alpha: {
    20: '33',
    10: '1A',
  },
  altYellow: {
    light: '#FAED54',
    dark: '#FDDD35CC',
  },
  cardBackground: '#1C2428',
  panelBackground: '#2A3439',
  cardShadow: 'rgba(0, 0, 0, 0.25)',
}
