import styled from 'styled-components'

import PaddleYellowLogo from 'components/images/logo-primary-yellow.svg'

const StyledLogoContainer = styled.div`
  display: block;
  margin-bottom: 60px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`

const StyledLogo = styled.img`
  width: 68px;
  height: 22px;
  @media only screen and (min-width: 1120px) {
    width: 90px;
    height: 28px;
  }
`

export function PrimaryLogo(): JSX.Element {
  return (
    <StyledLogoContainer>
      <StyledLogo src={PaddleYellowLogo} alt="Paddle.net" title="Paddle.net" />
    </StyledLogoContainer>
  )
}
