import styled from 'styled-components'

import { newPalette } from 'components/constants/colors'

export const StyledCardContainer = styled.div`
  background: ${newPalette.cardBackground};
  border: 1px solid ${newPalette.whiteAlpha[10]};
  box-shadow: 0px 0px 29px ${newPalette.cardShadow};
  border-radius: 18px;
`
