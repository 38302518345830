import { merge } from 'lodash'
import { addOrUpdateElement, RulAiVariableName, SourceScenario } from 'rulAi'

declare global {
  interface Window {
    _rulai_widget: {
      popin: () => {}
      hideLaunchIcon: () => {}
      showLaunchIcon: () => {}
      refresh: () => {}
      clearSavedState: () => {}
    }
    _rulai_get_load_script: () => {}
  }
}

interface TransactionProperties {
  transactionId?: string | null
  subscriptionId?: string | null
  token?: string | null
  chatbotHash?: string | null
}

export class RulAi {
  static enabled: boolean = true
  static loaded: boolean = false
  static opened: boolean = false

  static load() {
    if (this.loaded) {
      return this
    }

    this.withConfig({
      domain: 'rul.ai',
      pod: 'default',
      solution: 'db47260fb00c4b7f8d55a49e9b4a448b',
      listener: {
        // When the user enters the site from a magic link we will open the chatbot immediately, but
        // the `open` and `refresh` methods will be called while the widget is not initialised so we
        // have to clear the session storage before it is actually being used by the iframe itself.
        afterWidgetStart: () => (this.opened ? this.clear() : null),
        beforeWidgetStart: (state: string) => {
          if (this.opened) {
            return 'popin'
          }

          if (!this.enabled) {
            return 'disabled'
          }

          return 'hidden'
        },
      },
    })

    // We call the load script to trigger the loading of the chatbot. If this function does
    // not exist it means the script is not finished yet, but in that case the wd.js script
    // will automatically load the chatbot because the window._rulai config exists.
    window._rulai_get_load_script && window._rulai_get_load_script()

    this.loaded = true

    return this
  }

  static loadForV2() {
    if (this.loaded) {
      return this
    }

    this.withConfig({
      ui: {
        hideGreetingBadge: true,
        greetingTimer: 9999999,
        launchIconBgColor: '#1C2428',
        launchIconBoxShadow: '0 0 0 1px rgba(255, 255, 255, 0.10)',
        launchIconHoverColor: '#FDDD35',
        launchIconHoverTextColor: '#1C2428',
        launchIconImageLeft: '0px',
        launchIconTextColor: '#FDDD35',
      },
    })

    return this.load()
  }

  static setVariable(key: RulAiVariableName, value: any) {
    const variable = { name: key, value: value }

    if (!window._rulai) {
      window._rulai = {}
    }

    if (!window._rulai.slot) {
      window._rulai.slot = {
        variables: [variable],
      }
    } else {
      window._rulai.slot.variables = addOrUpdateElement(window._rulai.slot.variables, variable)
    }
  }

  static hide() {
    this.enabled = false

    window._rulai_widget && window._rulai_widget.hideLaunchIcon()
  }

  static show() {
    this.enabled = true

    window._rulai_widget && window._rulai_widget.showLaunchIcon()
  }

  static open(scenario: SourceScenario) {
    this.opened = true

    this.setVariable(RulAiVariableName.SourceScenario, scenario)

    // Unset the transaction related variables to avoid opening transaction data when not needed
    if (scenario !== SourceScenario.TransactionResult) {
      this.setVariable(RulAiVariableName.Hash, null)
      this.setVariable(RulAiVariableName.Token, null)
      this.setVariable(RulAiVariableName.EmailType, null)
      this.setVariable(RulAiVariableName.SubscriptionId, null)
      this.setVariable(RulAiVariableName.TransactionId, null)
    }

    // We will always refresh the session. This means that we will lose any previous session but otherwise the variables
    // will not be synced to the bot meaning that we might end up in an irrelevant context. When it's opened from within
    // this app we either have to escalate to the human-team or we know the transaction details and have to open these.
    this.refresh()

    window._rulai_widget && window._rulai_widget.popin()
  }

  static openForTransaction(props: TransactionProperties) {
    this.setVariable(RulAiVariableName.Hash, props.chatbotHash)
    this.setVariable(RulAiVariableName.Token, props.token)
    this.setVariable(RulAiVariableName.EmailType, 'paddle.net')
    this.setVariable(RulAiVariableName.SubscriptionId, props.subscriptionId)
    this.setVariable(RulAiVariableName.TransactionId, props.transactionId)

    this.open(SourceScenario.TransactionResult)
  }

  private static refresh() {
    window._rulai_widget && window._rulai_widget.refresh()

    return this
  }

  private static clear() {
    window._rulai_widget && window._rulai_widget.clearSavedState()

    return this
  }

  private static withConfig(config: any) {
    window._rulai = merge({}, config, window._rulai)
  }
}
