import { Collapse } from 'antd'
import styled from 'styled-components'

import { MediumGenericIcon } from 'components/common/icon'
import { Style } from 'components/common/react-types'
import { StyledTextSpan } from 'components/common/text'
import { H3Font, H4Font } from 'components/common/title'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import ArrowDownSvg from 'components/images/icons/arrow-down.svg'
import { PurchasesCardConstants } from 'components/pages/purchases-page/constants'

const { Panel } = Collapse

const StyledMainProductTitle = styled.div`
  color: ${newPalette.whiteAlpha[95]};
  @media only screen and ${device.small} {
    ${H4Font}
  }
  @media only screen and ${device.medium}, ${device.large} {
    ${H3Font}
  }
`

interface StyledSecondaryProductTitleProps {
  isFirstProduct?: boolean
  isLastProduct?: boolean
}

const StyledSecondaryProductTitle = styled.div<StyledSecondaryProductTitleProps>`
  color: ${newPalette.whiteAlpha[95]};
  margin-top: ${(props) => (props.isFirstProduct ? '20px' : '0')};
  margin-bottom: ${(props) => (props.isLastProduct ? '-4px' : '12px')};
  ${H4Font}
`

const StyledTextWithIconContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`

const StyledProductHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;
`

const CollapseContainer = styled.div`
  /* We need to use a selector with more specificity than the Antd one to be able to override it. */
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 0;
    padding-inline-start: 0;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-content-box {
    padding: 0;
  }
`

interface ProductProps {
  name: string
  count: number
  isSecondary?: boolean
  isFirstSecondaryProduct?: boolean
  isLastProduct?: boolean
}

function Product(props: ProductProps) {
  const countStyle: Style = {
    color: newPalette.gray[500],
  }

  if (props.isSecondary) {
    return (
      <StyledSecondaryProductTitle isFirstProduct={props.isFirstSecondaryProduct} isLastProduct={props.isLastProduct}>
        {props.name}
        <span style={countStyle}> x{props.count}</span>
      </StyledSecondaryProductTitle>
    )
  }

  return (
    <StyledMainProductTitle>
      {props.name}
      <span style={countStyle}> x{props.count}</span>
    </StyledMainProductTitle>
  )
}

interface ExtraProductsLabelProps {
  extraProductsCount: number
}

function ExtraProductsLabel(props: ExtraProductsLabelProps): JSX.Element | null {
  const ArrowDownIcon = (
    <MediumGenericIcon imgSource={ArrowDownSvg} imgTitle={PurchasesCardConstants.ArrowDownIconText} />
  )
  const productCount = props.extraProductsCount

  if (productCount === 0) {
    return null
  }

  const label =
    productCount === 1
      ? PurchasesCardConstants.SingularMoreProductsLabel
      : PurchasesCardConstants.PluralMoreProductsLabel

  return (
    <StyledTextWithIconContainer>
      <StyledTextSpan>
        + {productCount} {label}
      </StyledTextSpan>
      {ArrowDownIcon}
    </StyledTextWithIconContainer>
  )
}

interface ProductCollapsibleHeaderProps {
  productName: string
  productCount: number
  extraProductsCount: number
}

function ProductCollapsibleHeader(props: ProductCollapsibleHeaderProps): JSX.Element {
  const maybeExtraProducts = <ExtraProductsLabel extraProductsCount={props.extraProductsCount} />

  return (
    <StyledProductHeaderContainer>
      <Product name={props.productName} count={props.productCount} />
      {maybeExtraProducts}
    </StyledProductHeaderContainer>
  )
}

export interface ProductInterface {
  name: string
  count: number
}

interface ProductCollapsibleContainerProps {
  mainProduct: ProductInterface
  secondaryProductList: ProductInterface[]
}

export function ProductCollapsibleContainer(props: ProductCollapsibleContainerProps): JSX.Element {
  const extraProductCount = props.secondaryProductList.length

  const header = (
    <ProductCollapsibleHeader
      productName={props.mainProduct.name}
      productCount={props.mainProduct.count}
      extraProductsCount={extraProductCount}
    />
  )

  if (extraProductCount === 0) {
    return header
  }

  const secondaryProductComponentList = props.secondaryProductList.map((product, index) => {
    const isLastProduct = index === extraProductCount - 1
    const isFirstSecondaryProduct = index === 0
    return (
      <Product
        name={product.name}
        count={product.count}
        isSecondary
        isFirstSecondaryProduct={isFirstSecondaryProduct}
        isLastProduct={isLastProduct}
      />
    )
  })

  return (
    <CollapseContainer>
      <Collapse ghost>
        <Panel header={header} key={1} showArrow={false}>
          {secondaryProductComponentList}
        </Panel>
      </Collapse>
    </CollapseContainer>
  )
}
