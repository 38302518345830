export class APIErrResponse extends Error {
  error: APIErr | undefined
  meta: Meta | undefined
  constructor(message?: string, options?: ErrorOptions, error?: APIErr, meta?: Meta) {
    super(message, options)
    this.error = error
    this.meta = meta
  }
}

export interface APIErr {
  code: string
  detail: string
  type: string
  errors?: ErrItem[]
}

interface ErrItem {
  field: string
  message: string
}

export interface Meta {
  request_id: string
}

export const APIErrCodeInvalidField = 'invalid_field'
export const APIErrCodeBadRequest = 'bad_request'
