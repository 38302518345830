import { ConfigProvider } from 'antd'

import { JustChildren } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'

const inputTheme = {
  // General theme for all input components.
  token: {
    // Static border style.
    borderRadius: 4,
    colorBorder: newPalette.gray[400],

    // Border color when hovering.
    colorPrimaryHover: newPalette.gray[200],

    // Outline color and width, used when the component is in focus.
    controlOutline: newPalette.whiteAlpha[40],
    controlOutlineWidth: 4,

    // Background color of the input box, the add-on components, and the hovered option respectively.
    colorBgContainer: newPalette.blackAlpha[60],
    colorFillAlter: newPalette.blackAlpha[60],
    controlItemBgHover: newPalette.whiteAlpha[5],

    // Default and placeholder text color.
    colorText: newPalette.whiteAlpha[95],
    colorTextPlaceholder: newPalette.whiteAlpha[95],

    // Color of the suffix icon in the input box.
    colorTextQuaternary: newPalette.gray[400],

    // Input box font size.
    fontSizeLG: 14,

    // Font family.
    fontFamily: 'Graphik',
  },

  // Themes for specific input components.
  components: {
    // Theme for the select dropdown component.
    Select: {
      // Background color of the selected option.
      controlItemBgActive: newPalette.whiteAlpha[10],

      // Border radius of the options.
      borderRadiusSM: 0,
    },

    // Theme for the date-picker component.
    DatePicker: {
      // Color of the current date outline and the selected date background.
      colorPrimary: newPalette.primary.yellow,

      // Color of the bottom link referencing the current date.
      colorLink: newPalette.primary.yellow,
      colorLinkActive: newPalette.altYellow.dark,
      colorLinkHover: newPalette.altYellow.light,

      // Color of the arrows used to move to another month.
      colorIcon: newPalette.gray[400],

      // Color of the horizontal dividers.
      colorSplit: newPalette.whiteAlpha[10],
    },
  },
}

// Base Ant Design configuration for all input components inside it.
export function InputDesignConfig(props: JustChildren): JSX.Element {
  return <ConfigProvider theme={inputTheme}>{props.children}</ConfigProvider>
}
