export const LandingPageConstants = {
  PageTitle: 'How can we help you?',
  SectionTitle: 'What is Paddle?',
  Paragraph:
    'Paddle is a software reseller who partners with thousands of software companies worldwide to resell their digital products.',
  FAQLink: 'Visit paddle.com to learn more',
} as const

export const RecentPurchaseCardConstants = {
  CardTitle: 'I need help with a recent purchase',
  RefundIconText: 'Request refund',
  UpdateIconText: 'Update details',
  CancelSubscriptionIconText: 'Cancel subscription',
  ReceiptIconText: 'Request receipt',
  HelpIconText: 'Other queries',
  ButtonText: 'Look up my purchase',
} as const

export const UnrecognizedChargeCardConstants = {
  CardTitle: 'I’m not sure why Paddle has charged me',
  FirstParagraph:
    'Paddle partners with thousands of software companies around the world and resells their digital products.',
  SecondParagraph:
    'If you do not recognize a charge and suspect it’s fraudulent, we can help investigate and resolve the issue.',
  ButtonText: 'Get help',
} as const
