import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { LegacyGlobalStyle } from 'components/app/global-styles'
import { ContactPage } from 'components/pages/contact-page'
import { ContactPageRedirect } from 'components/pages/contact-page-redirect'

function LegacyApp(): JSX.Element {
  return (
    <BrowserRouter>
      <LegacyGlobalStyle />
      <Routes>
        <Route path="/" element={<ContactPage />} />
        <Route path="/contact" element={<ContactPageRedirect />} />
      </Routes>
    </BrowserRouter>
  )
}

export default LegacyApp
