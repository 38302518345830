/**
 * Preconditions, based on the guava module, comprises utility methods for
 * runtime checks.
 * There's not a lot of knowledge in these functions, just a common way of expressing these checks
 * As an example of the intent, note that checkArgument and checkState both check a boolean
 * expression and throw if it's not met. The function name and the name of the error thrown
 * provide us more information about the intent of that call.
 *
 * https://github.com/google/guava/wiki/PreconditionsExplained
 */

/**
 * The nil type represents either a null or undefined value.
 */
export type nil = null | undefined

// Whether a value is nil (null|undefined) or not
export function isNil(ref: any): ref is nil {
  return ref === null || ref === undefined
}

// Whether a value is not nil (or not)
export function isNotNil<T>(ref: T | nil): ref is T {
  return !isNil(ref)
}

// Check that a function arguments meet a condition, or fail throwing Error
export function checkArgument(argExpression: boolean, msg?: string): void {
  if (!argExpression) {
    const errorMessage = getValueOrDefault<string>(msg, 'Invalid argument')
    throw new Error(errorMessage)
  }
}

// Check that the state of a given instance/module is met, else throw an Error.
export function checkState(stateExpression: boolean, msg?: string): void {
  if (!stateExpression) {
    const errorMessage = getValueOrDefault<string>(msg, 'Invalid state')
    throw new Error(errorMessage)
  }
}

/**
 *  Denotes an unexpected null or undefined value was present.
 */
export class UnexpectedNilError extends Error {
  constructor() {
    super('Unexpected nil value')
  }
}

/**
 * checks that a reference is not null or undefined. Either throws an exception or returns its
 * non-nil value. It's useful as an expression: checkNotNil(dubiousValue).callSomeMethod()
 */
export function checkNotNil<T>(ref: T | nil, msg?: string): T {
  if (isNil(ref)) {
    if (!isNil(msg)) {
      return fail(msg)
    } else {
      throw new UnexpectedNilError()
    }
  }
  return ref
}

export function getValueOrDefault<T>(value: T | nil, defaultValue: T): T {
  return isNil(value) ? defaultValue : value
}
