import styled, { css } from 'styled-components'

import { newPalette } from 'components/constants/colors'

export const RegularBodyCopyTextStyle = css`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const StyledParagraph = styled.p`
  color: ${newPalette.gray[300]};
  ${RegularBodyCopyTextStyle}
`

export const StyledTextSpan = styled.span`
  color: ${newPalette.gray[300]};
  ${RegularBodyCopyTextStyle}
`

export const LargeBodyCopyTextStyle = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const StyledParagraphLargeBody = styled.p`
  ${LargeBodyCopyTextStyle}
`
