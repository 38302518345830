import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastProvider } from 'common/toast/provider'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { ErrorElement } from 'components/app/ErrorElement'
import { AntDesignConfig } from 'components/app/ant-design-config'
import { GlobalStyle } from 'components/app/global-styles'
import { PATHS } from 'components/app/paths'
import { ContactPageRedirect } from 'components/pages/contact-page-redirect'
import { ExpiredTokenPage } from 'components/pages/expired-token-page/expired-token-page'
import { FindPurchasePage } from 'components/pages/find-purchase-page/find-purchase-page'
import { GenericErrorPage } from 'components/pages/generic-error-page/generic-error-page'
import { LandingPage } from 'components/pages/landing-page/landing-page'
import { NotFoundPage } from 'components/pages/not-found-page/not-found-page'
import { PurchasesPage } from 'components/pages/purchases-page/purchases-page'
import { RecognizeEmailPage } from 'components/pages/recognize-email-page/recognize-email-page'
import { VerificationEmailSentPage } from 'components/pages/verification-email-sent-page/verification-email-sent-page'
import { VerifyEmailPage } from 'components/pages/verify-email-page/verify-email-page'

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    errorElement: <ErrorElement />,
    children: [
      { path: PATHS.ROOT, element: <LandingPage /> },
      { path: PATHS.VERIFY_EMAIL, element: <VerifyEmailPage /> },
      { path: PATHS.RECOGNIZE_EMAIL, element: <RecognizeEmailPage /> },
      { path: PATHS.FIND_PURCHASE, element: <FindPurchasePage /> },
      { path: PATHS.VERIFICATION_EMAIL_SENT, element: <VerificationEmailSentPage /> },
      { path: PATHS.PURCHASES, element: <PurchasesPage /> },
      { path: PATHS.EXPIRED_TOKEN, element: <ExpiredTokenPage /> },
      { path: PATHS.ERROR, element: <GenericErrorPage /> },
      { path: PATHS.CONTACT, element: <ContactPageRedirect /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
])

function App(): JSX.Element {
  return (
    <AntDesignConfig>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <GlobalStyle />
          <RouterProvider router={router} />
        </ToastProvider>
      </QueryClientProvider>
    </AntDesignConfig>
  )
}

export default App
