import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getCustomerIdentifier } from 'common/token'
import { environmentConfig } from 'environments/config/config'
import { FetchError } from 'queries'
import { fetchWithAuth } from 'queries/fetch-with-auth'
import { getOrdersURL } from 'queries/routes'
import adjustmentFullRefundRequested from 'testing/adjustment-full-refund-requested.json'
import adjustmentFullRefund from 'testing/adjustment-full-refund.json'
import adjustmentPartialRefund from 'testing/adjustment-partial-refund.json'
import adjustmentTaxRefundRequested from 'testing/adjustment-tax-refund-requested.json'
import failedPayment from 'testing/failed-payment.json'
import oneOffPastDueNoLogo from 'testing/one-off-past-due-no-logo.json'
import oneOffPastDue from 'testing/one-off-past-due.json'
import oneOffUnpaidInvoice from 'testing/one-off-unpaid-invoice.json'
import recurringActiveJPY from 'testing/recurring-active-jpy.json'
import recurringActiveNoSellerWebsite from 'testing/recurring-active-no-seller-website.json'
import recurringActive from 'testing/recurring-active.json'
import recurringCanceledInvoice from 'testing/recurring-canceled-canceled-invoice.json'
import recurringCanceledDuringTrial from 'testing/recurring-canceled-during-trial.json'
import recurringCanceledUnpaidInvoice from 'testing/recurring-canceled-unpaid-invoice.json'
import recurringCanceled from 'testing/recurring-canceled.json'
import recurringPaused from 'testing/recurring-paused.json'
import recurringScheduledCancel from 'testing/recurring-scheduled-cancel.json'
import recurringScheduledPause from 'testing/recurring-scheduled-pause.json'
import recurringScheduledResume from 'testing/recurring-scheduled-resume.json'
import recurringTrial from 'testing/recurring-trial.json'
import recurringUnpaidInvoice from 'testing/recurring-unpaid-invoice.json'
import { Order } from 'types/order'

export const GET_ORDERS_ERROR = 'Failed to get orders'

const ONE_HOUR = 60 * 1000 * 60

// The token is valid for 24 hours and we can keep it in cache during the whole
// lifetime of the token. Try to refresh the data every hour though if needed.
const DEFAULT_OPTIONS = {
  cacheTime: 24 * ONE_HOUR,
  staleTime: ONE_HOUR,
}

export interface OrderResponse {
  data: Array<Order>
}

export async function getOrders(token: string | null): Promise<OrderResponse> {
  const { USE_STATIC_ORDER_DATA } = environmentConfig

  if (USE_STATIC_ORDER_DATA) {
    const oneOffPastDueOrder = oneOffPastDue as Order
    const recurringCanceledUnpaidInvoiceOrder = recurringCanceledUnpaidInvoice as Order
    const recurringUnpaidInvoiceOrder = recurringUnpaidInvoice as Order
    const oneOffUnpaidInvoiceOrder = oneOffUnpaidInvoice as Order
    const recurringCanceledDuringTrialOrder = recurringCanceledDuringTrial as Order
    const recurringActiveJPYOrder = recurringActiveJPY as Order
    const recurringTrialOrder = recurringTrial as Order
    const recurringActiveOrder = recurringActive as Order
    const recurringCanceledOrder = recurringCanceled as Order
    const recurringPausedOrder = recurringPaused as Order
    const oneOffPastDueNoLogoOrder = oneOffPastDueNoLogo as Order
    const recurringCanceledInvoiceOrder = recurringCanceledInvoice as Order
    const recurringActiveNoSellerWebsiteOrder = recurringActiveNoSellerWebsite as Order
    const adjustmentFullRefundRequestedOrder = adjustmentFullRefundRequested as Order
    const adjustmentFullRefundOrder = adjustmentFullRefund as Order
    const adjustmentPartialRefundOrder = adjustmentPartialRefund as Order
    const adjustmentTaxRefundRequestedOrder = adjustmentTaxRefundRequested as Order
    const failedPaymentOrder = failedPayment as Order
    const recurringScheduledPauseOrder = recurringScheduledPause as Order
    const recurringScheduledCancelOrder = recurringScheduledCancel as Order
    const recurringScheduledResumeOrder = recurringScheduledResume as Order

    return {
      data: [
        failedPaymentOrder,
        adjustmentTaxRefundRequestedOrder,
        adjustmentPartialRefundOrder,
        adjustmentFullRefundOrder,
        adjustmentFullRefundRequestedOrder,
        recurringActiveNoSellerWebsiteOrder,
        recurringPausedOrder,
        oneOffPastDueOrder,
        recurringCanceledUnpaidInvoiceOrder,
        recurringUnpaidInvoiceOrder,
        oneOffUnpaidInvoiceOrder,
        recurringCanceledDuringTrialOrder,
        recurringActiveJPYOrder,
        recurringTrialOrder,
        recurringActiveOrder,
        recurringCanceledOrder,
        oneOffPastDueNoLogoOrder,
        recurringCanceledInvoiceOrder,
        recurringScheduledPauseOrder,
        recurringScheduledCancelOrder,
        recurringScheduledResumeOrder,
      ],
    }
  }

  const response = await fetchWithAuth(getOrdersURL(), token, {
    method: 'GET',
  })

  if (!response.ok) {
    const { status, statusText } = response
    const cause = await response.json()
    throw new FetchError(GET_ORDERS_ERROR, { cause }, status, statusText)
  }

  return response.json()
}

export const useGetOrders = (token: string | null, options?: UseQueryOptions<OrderResponse, FetchError>) => {
  return useQuery<OrderResponse, FetchError>(['orders', getCustomerIdentifier(token)], () => getOrders(token), {
    ...DEFAULT_OPTIONS,
    ...options,
    keepPreviousData: true,
  })
}
