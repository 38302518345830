export const PurchasesCardConstants = {
  TryAgainButton: 'Try again',
  GetHelpButton: 'Get help',
  ArrowDownIconText: 'Arrow down icon',
  SingularMoreProductsLabel: 'more product',
  PluralMoreProductsLabel: 'more products',
  FinalCardText: `Not found what you're looking for?`,
} as const

export const PurchasesPageConstants = {
  PageTitleSingleProduct: 'Is this the product or subscription you want help with?',
  PageTitleMultipleProducts: 'Which product or subscription do you want help with?',
} as const
