import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { useTurnstile } from 'react-turnstile'

import { PrimaryButton } from 'components/common/buttons'
import { TurnstileFormItem } from 'components/common/turnstile/form-item'
import { FieldNames, StaticText } from 'components/pages/verify-email-page/email-form/constants'
import { EmailAddressFormItem } from 'components/pages/verify-email-page/email-form/fields/email-address'

interface EmailFormProps {
  isRequestLoading: boolean
  didRequestFail: boolean
  onSubmit: (email: string, turnstileToken: string) => void
}

export function EmailForm({ isRequestLoading, didRequestFail, onSubmit }: EmailFormProps): JSX.Element {
  const [form] = Form.useForm()
  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <Form form={form} autoComplete="off" initialValues={{ [FieldNames.TurnstileToken]: '' }}>
      <EmailAddressFormItem isDisabled={isDisabled} />
      <TurnstileFormItem action="auth-link" fieldName={FieldNames.TurnstileToken} />
      <Form.Item noStyle>
        <SubmitEmailButton
          isRequestLoading={isRequestLoading}
          didRequestFail={didRequestFail}
          onSubmit={onSubmit}
          setIsDisabled={setIsDisabled}
        />
      </Form.Item>
    </Form>
  )
}

interface SubmitEmailButtonProps {
  isRequestLoading: boolean
  didRequestFail: boolean
  onSubmit(email: string, turnstileToken: string): void
  setIsDisabled(isDisabled: boolean): void
}

const SubmitEmailButton = ({ isRequestLoading, didRequestFail, onSubmit, setIsDisabled }: SubmitEmailButtonProps) => {
  const form = Form.useFormInstance()
  const values = Form.useWatch([], form)

  const [isSubmittable, setIsSubmittable] = useState(false)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsSubmittable(true),
      () => setIsSubmittable(false),
    )
  }, [form, values])

  const turnstile = useTurnstile()

  useEffect(() => {
    if (isRequestLoading) {
      setIsDisabled(true)
      setIsSubmittable(false)
    } else if (didRequestFail) {
      setTimeout(() => {
        setIsDisabled(false)
        setIsSubmittable(true)
        form.resetFields([FieldNames.TurnstileToken])
        turnstile?.reset()
      }, 5000)
    }
  }, [isRequestLoading, didRequestFail, setIsDisabled])

  const onClick = () => {
    if (isSubmittable) {
      const emailAddress = form.getFieldValue(FieldNames.EmailAddress)
      const turnstileToken = form.getFieldValue(FieldNames.TurnstileToken)

      onSubmit(emailAddress, turnstileToken)
    }
  }

  return (
    <PrimaryButton
      text={StaticText.SubmitLabel}
      isLoading={isRequestLoading}
      isDisabled={!isSubmittable}
      onClick={onClick}
    />
  )
}
