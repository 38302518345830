import * as React from 'react'
import styled from 'styled-components'

import { PrimaryLoadingSpinner, SecondaryLoadingSpinner } from 'components/common/loading-spinner'
import { Style } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'

// TODO: Reorder declarations to match convention when refactoring the primary button
// We will remove this enum when refactoring the primary button, no need to change it to a const for now
const enum ButtonStates {
  default = 'default',
  hover = 'hover',
  pressed = 'pressed',
  focus = 'focus',
  loading = 'loading',
  disabled = 'disabled',
}

const PRIMARY_BUTTON_STYLES = {
  [ButtonStates.default]: {
    boxShadow: `inset -0.5px -0.5px 1px ${newPalette.whiteAlpha[10]}`,
  },
  [ButtonStates.hover]: {
    boxShadow: `0px 0px 8px ${newPalette.primary.yellow}`,
  },
  [ButtonStates.pressed]: {
    opacity: '0.8',
  },
  [ButtonStates.focus]: {
    boxShadow: `inset -0.5px -0.5px 1px ${newPalette.whiteAlpha[10]}`,
    outline: '3px solid rgba(253, 221, 53, 0.6)',
  },
  [ButtonStates.loading]: {
    gap: '10px',
    opacity: '0.65',
    cursor: 'wait',
  },
  [ButtonStates.disabled]: {
    opacity: '0.4',
    cursor: 'not-allowed',
  },
}

interface StyledButtonLabelProps {
  primary?: boolean
}

const StyledButtonLabel = styled.div<StyledButtonLabelProps>(
  {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
  },
  ({ primary }) => ({
    color: primary ? newPalette.primary.black : newPalette.gray[200],
  }),
)

interface ButtonProps {
  text: string
  isLoading?: boolean
  isDisabled?: boolean
  // TODO once we upgrade the button to use antd we should check the best way to provide an
  //  `onClick` prop to it.
  onClick?(): void
}

interface PrimaryButtonState {
  hovered: boolean
  pressed: boolean
  focus: boolean
}

export class PrimaryButton extends React.Component<ButtonProps, PrimaryButtonState> {
  constructor(props: ButtonProps) {
    super(props)
    this.state = {
      hovered: false,
      pressed: false,
      focus: false,
    }
  }

  render() {
    const stateStyle = PRIMARY_BUTTON_STYLES[this.getInteractiveState()]

    const baseButtonStyle: Style = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      background: newPalette.primary.yellow,
      borderStyle: 'none',
      borderRadius: '4px',
      height: '40px',
      padding: '12px',
      outline: 'none',
      cursor: 'pointer',
      ...stateStyle,
    }
    const maybeLoadingIcon = this.props.isLoading ? <PrimaryLoadingSpinner /> : null

    const onClick = this.props.isLoading || this.props.isDisabled ? undefined : this.props.onClick

    return (
      <button
        style={baseButtonStyle}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onClick={onClick}
      >
        {maybeLoadingIcon}
        <StyledButtonLabel primary>{this.props.text}</StyledButtonLabel>
      </button>
    )
  }

  private onMouseEnter = () => {
    this.setState({ hovered: true })
  }

  private onMouseLeave = () => {
    this.setState({ hovered: false })
    this.setState({ pressed: false })
  }

  private onMouseUp = () => {
    this.setState({ pressed: false })
    this.setState({ focus: false })
  }

  private onMouseDown = () => {
    this.setState({ pressed: true })
  }

  private onFocus = () => {
    this.setState({ focus: true })
  }

  private onBlur = () => {
    this.setState({ focus: false })
  }

  private getInteractiveState = (): ButtonStates => {
    if (this.props.isLoading) {
      return ButtonStates.loading
    }

    if (this.props.isDisabled) {
      return ButtonStates.disabled
    }

    if (this.state.pressed) {
      return ButtonStates.pressed
    }

    if (this.state.hovered) {
      return ButtonStates.hover
    }

    if (this.state.focus) {
      return ButtonStates.focus
    }

    return ButtonStates.default
  }
}

interface StyledSecondaryButtonProps {
  isLoading?: boolean
}

const StyledSecondaryButton = styled.button<StyledSecondaryButtonProps>(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: newPalette.whiteAlpha[5],
    border: `1px solid ${newPalette.gray[300]}`,
    borderRadius: 4,
    height: 40,
    padding: 12,
    cursor: 'pointer',
    gap: 8,

    '&:focus-visible': {
      outline: `4px solid ${newPalette.whiteAlpha[40]}`,
    },
    '&:hover': {
      boxShadow: `0px 0px 8px ${newPalette.whiteAlpha[80]}`,
      outline: 'none',
    },
    '&:active': {
      opacity: 0.8,
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ({ isLoading }) => ({
    opacity: isLoading ? 0.65 : 1,
    pointerEvents: isLoading ? 'none' : 'auto',
  }),
)

export function SecondaryButton(props: ButtonProps): JSX.Element {
  const maybeLoadingIcon = props.isLoading ? <SecondaryLoadingSpinner /> : null

  return (
    <StyledSecondaryButton isLoading={props.isLoading} onClick={props.onClick} disabled={props.isDisabled}>
      {maybeLoadingIcon}
      <StyledButtonLabel>{props.text}</StyledButtonLabel>
    </StyledSecondaryButton>
  )
}

export const StyledButtonFakeLink = styled.button`
  color: ${newPalette.whiteAlpha[95]};
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
`
