export function fail(msg: string): never {
  throw new Error(msg)
}

/**
 *  Function that should be impossible to call.
 *
 *  Basically, use this if you want typescript to statically verify something like whether a switch
 *  statement is exhaustive and cannot reach the default case.  Basically, the compiler will only
 *  allow you to call this function if it can statically prove that no possible value could allow
 *  your code to actually get to the call.  This means that if you put this in the default case of a
 *  switch statement, you need to handle every possible value for the thing you are switching on or
 *  else the compiler will yell at you.  If someone else adds another value to the enum or whatever
 *  later on, they will get a compiler error until they fix this switch as well. On the off chance
 *  that you get to this function anyway (say, js code passed an invalid value to your switch),
 *  this will use msg to provide a useful error message.
 */
export function assertUnreachable(msg: string, x: never): never {
  return fail(msg + x)
}
