import { useFlags } from 'launchdarkly-react-client-sdk'
import { RulAi } from 'rulAi'

import App from 'components/app/App'
import LegacyApp from 'components/app/LegacyApp'

export function AppDispatcher(): JSX.Element {
  // This is how we get LaunchDarkly flags to branch logic in our code:
  const { paddleNetEnableUiV2 } = useFlags()

  if (paddleNetEnableUiV2) {
    RulAi.loadForV2()
    return <App />
  }
  RulAi.load().show()

  return <LegacyApp />
}
