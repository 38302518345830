import { isNil, isNotNil } from 'common/checks'
import { FormatCurrency } from 'common/currency'
import { DurationInDays, FormatISODate } from 'common/date'
import { useGetToken } from 'hooks/useGetToken'
import { RulAi } from 'rulAi'
import styled from 'styled-components'
import { Nullable } from 'types/nullable'
import { LineItem, Order } from 'types/order'
import { ScheduledChange } from 'types/subscription'

import { SecondaryButton } from 'components/common/buttons'
import { StyledCardContainer } from 'components/common/card'
import { StyledTextSpan } from 'components/common/text'
import { H4Font } from 'components/common/title'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import ProductLogoFallback from 'components/images/product-logo.svg'
import { PurchasesCardConstants } from 'components/pages/purchases-page/constants'
import { ProductInterface, ProductCollapsibleContainer } from 'components/pages/purchases-page/product-collape'
import { ScheduledChangeMessage } from 'components/pages/purchases-page/scheduled-changes'
import {
  SubscriptionStatus,
  SubscriptionStatusTypeKeys,
  SubscriptionStatusTypes,
} from 'components/pages/purchases-page/subscription-status'
import { TransactionStatusTypes } from 'components/pages/purchases-page/transaction-status'
import { TransactionStoryPoint, TransactionTimeline } from 'components/pages/purchases-page/transaction-timeline'

const StyledCardTransactionContainer = styled(StyledCardContainer)`
  @media only screen and ${device.small} {
    padding: 20px;
  }
  @media only screen and ${device.medium}, ${device.large} {
    padding: 24px 24px 39px;
  }
`

const StyledImageAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

const StyledImageProductLogo = styled.img`
  height: 48px;
`

const StyledDescriptionWithStatusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 4px;
  margin-bottom: 12px;
`

const StyledLink = styled.a`
  color: ${newPalette.gray[300]};
  margin-left: 0.25em;
`

const StyledBreakLine = styled.hr`
  border: 1.2px solid ${newPalette.whiteAlpha[10]};
  margin: 24px 0;
`

const StyledPaymentInfoContainer = styled.div`
  display: flex;
  @media only screen and ${device.small} {
    flex-direction: column;
    row-gap: 40px;
  }
`

const StyledPaymentContainer = styled.div`
  @media only screen and ${device.medium}, ${device.large} {
    flex-basis: 50%;
  }
`

const StyledPriceOrTrialExpiration = styled.span`
  color: ${newPalette.whiteAlpha[95]};
  ${H4Font}
`

interface DescriptionWithStatusProps {
  description: string
  statusType: SubscriptionStatusTypeKeys
  scheduledChange: Nullable<ScheduledChange>
  isSubscription: boolean
  linkText?: string
  linkRef?: string
}

function DescriptionWithStatus(props: DescriptionWithStatusProps): JSX.Element {
  return (
    <StyledDescriptionWithStatusContainer>
      <StyledTextSpan>
        {props.description}
        <StyledLink href={props.linkRef} target="_blank">
          {props.linkText}
        </StyledLink>
      </StyledTextSpan>
      {props.isSubscription && <SubscriptionStatus type={props.statusType} />}
      {props.isSubscription && isNotNil(props.scheduledChange) && (
        <ScheduledChangeMessage type={props.scheduledChange.action} date={props.scheduledChange.effective_at} />
      )}
    </StyledDescriptionWithStatusContainer>
  )
}

interface DescriptionWithTimelineProps {
  description: string
  storyPoints?: TransactionStoryPoint[]
  isTrial: boolean
}

function DescriptionWithTimeline(props: DescriptionWithTimelineProps): JSX.Element {
  const maybeTransactionTimeline =
    isNotNil(props.storyPoints) && !props.isTrial ? <TransactionTimeline storyPoints={props.storyPoints} /> : null

  return (
    <StyledDescriptionWithStatusContainer>
      <StyledTextSpan>{props.description}</StyledTextSpan>
      {maybeTransactionTimeline}
    </StyledDescriptionWithStatusContainer>
  )
}

interface PaymentProps {
  description: string
  isTrial?: boolean
  priceOrTrialExpiration: string
  paymentDate: Nullable<string>
  storyPoints?: TransactionStoryPoint[]
}

function Payment(props: PaymentProps): JSX.Element {
  return (
    <StyledPaymentContainer>
      <DescriptionWithTimeline
        isTrial={props.isTrial!}
        description={props.description}
        storyPoints={props.storyPoints}
      />
      <StyledPriceOrTrialExpiration>{props.priceOrTrialExpiration}</StyledPriceOrTrialExpiration>
      {props.paymentDate && <StyledTextSpan>{props.paymentDate}</StyledTextSpan>}
    </StyledPaymentContainer>
  )
}

interface PurchasesCardProps {
  order: Order
}

function getLogo(order: Order): string {
  if (order.details?.line_items[0].product?.image_url) {
    return order.details?.line_items[0].product?.image_url
  }

  if (order.seller.image_url) {
    return order.seller.image_url
  }

  return ProductLogoFallback
}

function getSellerName(order: Order): string {
  if (order.seller.display_name) {
    return order.seller.display_name
  }

  return order.seller.legal_name
}

function getProductBillingCycle(order: Order): string {
  if (order.subscription?.display_billing_cycle) {
    return order.subscription?.display_billing_cycle
  }

  return 'One-time purchase'
}

function getSecondaryProducts(order: Order): ProductInterface[] {
  if (order.details?.line_items.length === 1) {
    return []
  }

  let d: ProductInterface[] = []

  order.details?.line_items.forEach((v: LineItem, index: number, array: LineItem[]) => {
    if (index !== 0) {
      d.push({
        name: v.product?.name!,
        count: v.quantity,
      })
    }
  })

  return d
}

function getLastPaymentDescription(order: Order): string {
  if (order.subscription?.status === SubscriptionStatusTypes.trialing) {
    return 'Trial ends in'
  }

  return 'Last payment'
}

function getLastPaymentDate(order: Order): Nullable<string> {
  if (order.subscription?.status === SubscriptionStatusTypes.trialing) {
    return null
  }

  if (
    order.last_transaction?.extended_status === TransactionStatusTypes.unpaid ||
    order.last_transaction?.extended_status === TransactionStatusTypes.failedPayment ||
    order.last_transaction?.extended_status === TransactionStatusTypes.pastDue
  ) {
    return ' due on ' + FormatISODate(order.last_transaction?.billed_at!)
  }

  if (order.last_transaction?.paid_at) {
    return ' on ' + FormatISODate(order.last_transaction?.paid_at)
  }

  return null
}

function getNextPaymentAmount(order: Order): string {
  if (isNil(order.subscription?.next_transaction)) {
    return '-'
  }

  return FormatCurrency(
    order.subscription?.next_transaction?.details.totals.currency_code!,
    order.subscription?.next_transaction?.details.totals.total!,
  )
}

function getNextPaymentDate(order: Order): Nullable<string> {
  if (isNil(order.subscription?.next_transaction)) {
    return null
  }

  return ' due on ' + FormatISODate(order.subscription?.next_transaction?.billing_period?.starts_at!)
}

function getLastPaymentAmountOrTrialLength(order: Order): string {
  if (order.subscription?.status === SubscriptionStatusTypes.trialing) {
    return DurationInDays(order.subscription?.trial_dates?.ends_at!)
  }

  if (isNil(order.last_transaction)) {
    return 'Unknown'
  }

  return FormatCurrency(
    order.last_transaction?.details.totals.currency_code!,
    order.last_transaction?.details.totals.total!,
  )
}

function getLastTransactionStatus(order: Order): TransactionStoryPoint[] | undefined {
  if (isNil(order.last_transaction)) {
    return
  }

  return [
    {
      type: order.last_transaction?.extended_status!,
      description: '',
    },
  ]
}

function getSellerWebsite(order: Order): string | undefined {
  if (isNil(order.seller.website)) {
    return
  }

  return order.seller.website
}

export function PurchasesCard({ order }: PurchasesCardProps): JSX.Element {
  const token = useGetToken()

  const handleGetHelpClick = () => {
    if (order.customer_id.startsWith('ctm_')) {
      RulAi.openForTransaction({
        transactionId: order.last_transaction?.id,
        subscriptionId: order.subscription_id,
        token: token,
      })
    } else {
      RulAi.openForTransaction({
        chatbotHash: order.last_transaction?.chatbot_hash,
      })
    }
  }

  return (
    <StyledCardTransactionContainer>
      <StyledImageAndButtonContainer>
        <StyledImageProductLogo
          src={getLogo(order)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = ProductLogoFallback
          }}
        />
        <SecondaryButton text={PurchasesCardConstants.GetHelpButton} onClick={handleGetHelpClick} />
      </StyledImageAndButtonContainer>

      <DescriptionWithStatus
        description={getProductBillingCycle(order) + ' from'}
        statusType={order.subscription?.status!}
        scheduledChange={order.subscription?.scheduled_change!}
        isSubscription={!!order.subscription}
        linkText={getSellerName(order)}
        linkRef={getSellerWebsite(order)}
      />

      <ProductCollapsibleContainer
        mainProduct={{
          count: order.details?.line_items[0].quantity!,
          name: order.details?.line_items[0].product?.name!,
        }}
        secondaryProductList={getSecondaryProducts(order)}
      />

      <StyledBreakLine />

      <StyledPaymentInfoContainer>
        <Payment
          description={getLastPaymentDescription(order)}
          priceOrTrialExpiration={getLastPaymentAmountOrTrialLength(order)}
          paymentDate={getLastPaymentDate(order)}
          storyPoints={getLastTransactionStatus(order)}
          isTrial={order.subscription?.status === SubscriptionStatusTypes.trialing}
        />
        {order.subscription && (
          <Payment
            description="Next payment"
            priceOrTrialExpiration={getNextPaymentAmount(order)}
            paymentDate={getNextPaymentDate(order)}
          />
        )}
      </StyledPaymentInfoContainer>
    </StyledCardTransactionContainer>
  )
}
