import { ConfigProvider } from 'antd'
import { checkNotNil } from 'common/checks'

import { JustChildren } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'

const antTheme = {
  // General theme for all Ant components.
  token: {
    // Background color of all dropdown panels.
    colorBgElevated: newPalette.panelBackground,

    // Error color.
    colorError: newPalette.red[400],
  },
}

// Insert the popup element into the parent container of the target element.
const getPopupContainer = (target?: HTMLElement | undefined): HTMLElement => {
  return target ? checkNotNil(target.parentElement) : document.body
}

// Base Ant Design configuration for all Ant components in the app.
export function AntDesignConfig(props: JustChildren): JSX.Element {
  return (
    <ConfigProvider theme={antTheme} getPopupContainer={getPopupContainer}>
      {props.children}
    </ConfigProvider>
  )
}
