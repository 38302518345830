import { randomID } from 'common/random'
import { Toast } from 'common/toast/component'
import { ToastContext } from 'common/toast/context'
import { TOAST_TYPES, ToastType } from 'common/toast/types'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Nullable } from 'types/nullable'

import { JustChildren } from 'components/common/react-types'

interface ToastData {
  id: string
  type: ToastType
  message: string
}

export function ToastProvider({ children }: JustChildren): JSX.Element {
  const [toast, setToast] = useState<Nullable<ToastData>>(null)

  const open = (message: string, type: ToastType) => setToast({ id: randomID(), type, message })
  const toastMethods = useMemo(() => {
    return {
      success: (message: string) => open(message, TOAST_TYPES.SUCCESS),
      error: (message: string) => open(message, TOAST_TYPES.ERROR),
    }
  }, [])

  const close = () => setToast(null)
  const toastComponent = toast ? <Toast type={toast.type} message={toast.message} close={close} key={toast.id} /> : null
  const toastPortal = createPortal(toastComponent, document.body)

  return (
    <ToastContext.Provider value={toastMethods}>
      {children}
      {toastPortal}
    </ToastContext.Provider>
  )
}
