import styled from 'styled-components'

import { newPalette } from 'components/constants/colors'
import CloseSvg from 'components/images/icons/close.svg'

const StyledCloseButtonContentContainer = styled.button`
  background: none;
  outline: none;
  border: none;

  line-height: 0px;
  padding: 2px;
  border-radius: 4px;

  user-select: none;
  cursor: pointer;

  :focus-visible {
    outline: 2px solid ${newPalette.blackAlpha[60]};
  }

  :hover {
    background: ${newPalette.gray[600]};
  }

  :active {
    background: ${newPalette.gray[700]};
  }
`

interface CloseButtonProps {
  onClose(): void
}

export function CloseButton(props: CloseButtonProps): JSX.Element {
  return (
    <StyledCloseButtonContentContainer onClick={props.onClose}>
      <img src={CloseSvg} alt="" draggable={false} />
    </StyledCloseButtonContentContainer>
  )
}
