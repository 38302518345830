import { assertUnreachable } from 'components/common/errors'
import { SmallGenericIcon } from 'components/common/icon'
import { ObjectValues } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'
import CompleteSvg from 'components/images/icons/complete.svg'
import PastDueSvg from 'components/images/icons/past-due.svg'
import PausedSvg from 'components/images/icons/paused.svg'
import CanceledSvg from 'components/images/icons/subtract.svg'
import { GenericStatus, SharedStatusProps } from 'components/pages/purchases-page/status'

const SubscriptionStatusIconComponentTitles = {
  active: 'Active subscription icon',
  trialing: 'Trialing subscription icon',
  paused: 'Paused subscription icon',
  pastDue: 'Past due icon',
  canceled: 'Canceled subscription icon',
} as const

const SubscriptionStatusSmallIconComponents = {
  active: <SmallGenericIcon imgSource={CompleteSvg} imgTitle={SubscriptionStatusIconComponentTitles.active} />,
  trialing: <SmallGenericIcon imgSource={CompleteSvg} imgTitle={SubscriptionStatusIconComponentTitles.trialing} />,
  paused: <SmallGenericIcon imgSource={PausedSvg} imgTitle={SubscriptionStatusIconComponentTitles.paused} />,
  pastDue: <SmallGenericIcon imgSource={PastDueSvg} imgTitle={SubscriptionStatusIconComponentTitles.pastDue} />,
  canceled: <SmallGenericIcon imgSource={CanceledSvg} imgTitle={SubscriptionStatusIconComponentTitles.canceled} />,
} as const

export const SubscriptionStatusTypes = {
  active: 'active',
  trialing: 'trialing',
  paused: 'paused',
  pastDue: 'past_due',
  canceled: 'canceled',
} as const

export type SubscriptionStatusTypeKeys = ObjectValues<typeof SubscriptionStatusTypes>

interface SubscriptionStatusProps {
  type: SubscriptionStatusTypeKeys
}

export function SubscriptionStatus(props: SubscriptionStatusProps): JSX.Element {
  switch (props.type) {
    case SubscriptionStatusTypes.active:
      return <ActiveStatus text={'Active'} />
    case SubscriptionStatusTypes.trialing:
      return <TrialingStatus text={'Trialing'} />
    case SubscriptionStatusTypes.paused:
      return <PausedStatus text={'Paused'} />
    case SubscriptionStatusTypes.pastDue:
      return <PastDue text={'Past due'} />
    case SubscriptionStatusTypes.canceled:
      return <CanceledStatus text={'Canceled'} />
    default:
      return assertUnreachable('Invalid result type: ', props.type)
  }
}

function ActiveStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={SubscriptionStatusSmallIconComponents.active}
      textColor={newPalette.green[200]}
      background={newPalette.green[200]}
    />
  )
}

function TrialingStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={SubscriptionStatusSmallIconComponents.trialing}
      textColor={newPalette.green[200]}
      background={newPalette.green[200]}
    />
  )
}

function PausedStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={SubscriptionStatusSmallIconComponents.paused}
      textColor={newPalette.gray[200]}
      background={newPalette.gray[200]}
    />
  )
}

function PastDue(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={SubscriptionStatusSmallIconComponents.pastDue}
      textColor={newPalette.red[300]}
      background={newPalette.red[300]}
    />
  )
}

function CanceledStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={SubscriptionStatusSmallIconComponents.canceled}
      textColor={newPalette.gray[200]}
      background={newPalette.gray[200]}
    />
  )
}
