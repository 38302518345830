export const PATHS = {
  ROOT: '/',
  VERIFY_EMAIL: '/verify-email',
  FIND_PURCHASE: '/find-purchase',
  RECOGNIZE_EMAIL: '/recognize-email',
  VERIFICATION_EMAIL_SENT: '/verification-email-sent',
  PURCHASES: '/purchases',
  EXPIRED_TOKEN: '/expired-token',
  ERROR: '/something-went-wrong',
  CONTACT: '/contact',
}

export const DISABLE_CHATBOT_IN_ROUTES = [
  PATHS.ROOT,
  PATHS.PURCHASES,
  PATHS.EXPIRED_TOKEN,
  PATHS.VERIFY_EMAIL,
  PATHS.FIND_PURCHASE,
]
