import { getValueOrDefault, isNil } from 'common/checks'
import { CurrencyOption, getCurrencySymbol, sortCurrencyOptions } from 'common/currency'
import { useGetCurrencies } from 'queries/get-currencies'
import { PostSearchCustomersRequest, SearchCustomersResponse, usePostSearchCustomers } from 'queries/search-customers'
import { useMemo, useState, Dispatch } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { RulAi, SourceScenario } from 'rulAi'
import styled, { css } from 'styled-components'
import { Nullable } from 'types/nullable'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { StyledButtonFakeLink } from 'components/common/buttons'
import { StyledCardContainer } from 'components/common/card'
import { StyledGridContainer } from 'components/common/grid'
import { MediumGenericIcon } from 'components/common/icon'
import { YellowLoadingSpinner } from 'components/common/loading-spinner'
import { LargeBodyCopyTextStyle, RegularBodyCopyTextStyle, StyledParagraph } from 'components/common/text'
import { StyledPageTitle } from 'components/common/title'
import { StyledWarningCardContainer } from 'components/common/warning-card'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import WarningSvg from 'components/images/icons/warning.svg'
import {
  FindPurchasePageConstants,
  KoreanCardBrandLabels,
  PaymentMethodLabels,
  PaymentMethods,
} from 'components/pages/find-purchase-page/constants'
import { PaymentForm } from 'components/pages/find-purchase-page/payment-form/form'

const StyledFindPurchaseGridContainer = styled(StyledGridContainer)`
  row-gap: 24px;
`

const responsiveWidth = css`
  @media only screen and ${device.small} {
    grid-column-end: span 6;
  }
  @media only screen and ${device.medium}, ${device.large} {
    grid-column-end: span 8;
  }
`

const TextParagraphGridItem = styled.p`
  color: ${newPalette.gray[300]};
  @media only screen and ${device.small}, ${device.medium} {
    ${RegularBodyCopyTextStyle}
  }
  @media only screen and ${device.large} {
    ${LargeBodyCopyTextStyle}
  }
  ${responsiveWidth}
`

const DisclaimerParagraphGridItem = styled(StyledParagraph)`
  ${responsiveWidth}
`

const StyledWarningCardContainerGridItem = styled(StyledWarningCardContainer)`
  display: flex;
  ${responsiveWidth}
`

const StyledWarningIconContainer = styled.div`
  margin-right: 12px;
`

const StyledWarningParagraphContainer = styled.div`
  color: ${newPalette.whiteAlpha[95]};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const StyledCardGridItem = styled(StyledCardContainer)`
  padding: 24px;
  @media only screen and ${device.small} {
    margin-top: 36px;
  }
  @media only screen and ${device.medium}, ${device.large} {
    margin-top: 16px;
  }
  ${responsiveWidth}
`

const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

interface FindPurchaseCardProps {
  currencies: Array<CurrencyOption>
  setAreResultsEmpty: Dispatch<boolean>
}

function FindPurchaseCard({ currencies, setAreResultsEmpty }: FindPurchaseCardProps): JSX.Element {
  // Compute the list of payment method options.
  const paymentMethods = useMemo(() => {
    return Object.entries(PaymentMethodLabels).map(([value, label]) => ({ label, value }))
  }, [])

  // Compute the list of Korean card brands
  const koreanCardBrands = useMemo(() => {
    return Object.entries(KoreanCardBrandLabels).map(([value, label]) => ({ label, value }))
  }, [])

  const navigate = useNavigate()

  const [request, setRequest] = useState<PostSearchCustomersRequest>()
  const {
    isLoading,
    isFetching,
    isError: didRequestFail,
  } = usePostSearchCustomers(request, {
    onSuccess: (response: SearchCustomersResponse) => {
      const customers = response.data

      if (customers.length === 0) {
        setAreResultsEmpty(true)
      } else {
        setAreResultsEmpty(false)

        const navigateOptions = { state: { previousLocation: PATHS.FIND_PURCHASE, customers } }
        navigate(PATHS.RECOGNIZE_EMAIL, navigateOptions)
      }
    },
  })

  const handleSearchCustomers = (
    paidAmount: number,
    currencyCode: string,
    paymentDate: string,
    paymentMethod: string,
    koreanCardBrand: Nullable<string>,
    lastCardDigits: Nullable<string>,
    turnstileToken: string,
  ) => {
    const newRequest: PostSearchCustomersRequest = {
      amount: paidAmount,
      currency_code: currencyCode,
      payment_date: paymentDate,
      payment_method: paymentMethod,
      underlying_payment_method: getValueOrDefault(koreanCardBrand, undefined),
      card_last_4: getValueOrDefault(lastCardDigits, undefined),
      cfTurnstileToken: turnstileToken,
    }

    if (
      paymentMethod === PaymentMethods.KakaoPay ||
      paymentMethod === PaymentMethods.NaverPay ||
      paymentMethod === PaymentMethods.SamsungPay ||
      paymentMethod === PaymentMethods.Payco
    ) {
      newRequest.underlying_payment_method = paymentMethod
      newRequest.payment_method = PaymentMethods.SouthKorea
    }

    setRequest(newRequest)
  }

  return (
    <StyledCardGridItem>
      <PaymentForm
        currencies={currencies}
        paymentMethods={paymentMethods}
        koreanCardBrands={koreanCardBrands}
        isRequestLoading={isLoading && isFetching}
        didRequestFail={didRequestFail}
        onSubmit={handleSearchCustomers}
      />
    </StyledCardGridItem>
  )
}

function NoResultsCard(): JSX.Element {
  return (
    <StyledWarningCardContainerGridItem>
      <StyledWarningIconContainer>
        <MediumGenericIcon imgSource={WarningSvg} imgTitle="Warning icon" />
      </StyledWarningIconContainer>
      <StyledWarningParagraphContainer>
        We couldn’t find a purchase matching these details. Check the details you provided and try again, or if you’re
        confident the information you’ve provided is accurate{' '}
        <StyledButtonFakeLink onClick={() => RulAi.open(SourceScenario.NoResultsFromSearch)}>
          contact our team
        </StyledButtonFakeLink>{' '}
        for help.
      </StyledWarningParagraphContainer>
    </StyledWarningCardContainerGridItem>
  )
}

export function FindPurchasePage(): JSX.Element {
  // TODO in case of free transaction search which trigger and escalate response we might need to trigger something with `escalate` scenario and otherwise check with Mosthafa what is needed here.

  const location = useLocation()
  const previousLocation: Nullable<string> = location.state?.previousLocation ?? null
  const isMainPage = isNil(previousLocation) || previousLocation === PATHS.ROOT

  const pageTitle = isMainPage
    ? FindPurchasePageConstants.MainPageTitle
    : FindPurchasePageConstants.AlternativePageTitle
  const pageParagraph = isMainPage
    ? FindPurchasePageConstants.MainPageParagraph
    : FindPurchasePageConstants.AlternativePageParagraph

  // Fetch the supported currencies.
  const { data: currencyData, isInitialLoading, isError } = useGetCurrencies({ refetchOnWindowFocus: false })
  const currencies = useMemo(() => {
    const options = (currencyData?.data ?? []).map((currency) => ({
      code: currency.code,
      symbol: getCurrencySymbol(currency.code),
      name: currency.name,
      decimalPlaces: currency.decimal_places,
    }))
    return sortCurrencyOptions(options)
  }, [currencyData])

  const [areResultsEmpty, setAreResultsEmpty] = useState(false)

  if (isError) {
    return <Navigate to={{ pathname: PATHS.ERROR }} replace={true} />
  }

  if (isInitialLoading || currencies.length === 0) {
    return (
      <BasePage>
        <StyledSpinnerContainer>
          <YellowLoadingSpinner />
        </StyledSpinnerContainer>
      </BasePage>
    )
  }

  return (
    <BasePage>
      <StyledPageTitle style={{ marginBottom: 24 }}>{pageTitle}</StyledPageTitle>
      <StyledFindPurchaseGridContainer>
        <TextParagraphGridItem>{pageParagraph}</TextParagraphGridItem>
        <FindPurchaseCard currencies={currencies} setAreResultsEmpty={setAreResultsEmpty} />
        {areResultsEmpty && <NoResultsCard />}
        <DisclaimerParagraphGridItem>{FindPurchasePageConstants.DisclaimerParagraph}</DisclaimerParagraphGridItem>
      </StyledFindPurchaseGridContainer>
    </BasePage>
  )
}
