import { environmentConfig } from 'environments/config/config'
import Turnstile from 'react-turnstile'

import { Style } from 'components/common/react-types'

export interface TurnstileWidgetProps {
  action: string
  style?: Style
  getTokenCallback: (token: string) => void
  shouldShow: (show: boolean) => void
}

export function TurnstileWidget({ action, style, getTokenCallback, shouldShow }: TurnstileWidgetProps): JSX.Element {
  const { CF_TURNSTILE_SITE_KEY } = environmentConfig

  return (
    <Turnstile
      sitekey={CF_TURNSTILE_SITE_KEY}
      onVerify={(token) => getTokenCallback(token)}
      onExpire={(token) => getTokenCallback(token)}
      refreshExpired="auto"
      appearance="interaction-only"
      onBeforeInteractive={() => shouldShow(true)}
      action={`pdl-net_${action}`}
      style={style}
    />
  )
}
