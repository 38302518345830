import jwt_decode from 'jwt-decode'

interface Token {
  sub: string
  exp: number
}

export function decodeToken(token: string | null) {
  if (!token) {
    return null
  }

  try {
    return jwt_decode(token) as Token
  } catch (error) {
    console.debug('error decoding token', error)
  }

  return null
}

export function getCustomerIdentifier(token: string | null): string {
  const decodedToken = decodeToken(token)

  return decodedToken?.sub || ''
}
