import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { PrimaryButton } from 'components/common/buttons'
import { StyledParagraphLargeBody } from 'components/common/text'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import { NotFoundPageConstants } from 'components/pages/not-found-page/constants'

const NotFoundTitle = styled.div`
  color: ${newPalette.whiteAlpha[95]};
  font-style: normal;
  font-weight: 500;
  margin-bottom: 28px;

  @media only screen and ${device.small} {
    font-size: 36px;
    line-height: 44px;
  }
  @media only screen and ${device.medium} {
    font-size: 48px;
    line-height: 67px;
  }
  @media only screen and ${device.large} {
    font-size: 60px;
    line-height: 90px;
  }
`

const NotFoundParagraph = styled(StyledParagraphLargeBody)`
  color: ${newPalette.whiteAlpha[95]};
  margin-bottom: 20px;
`

export function NotFoundPage(): JSX.Element {
  const navigate = useNavigate()

  return (
    <BasePage>
      <NotFoundTitle>{NotFoundPageConstants.PageTitle}</NotFoundTitle>
      <NotFoundParagraph>{NotFoundPageConstants.PageParagraph}</NotFoundParagraph>
      <PrimaryButton text={NotFoundPageConstants.ButtonLabel} onClick={() => navigate(PATHS.ROOT)} />
    </BasePage>
  )
}
