import { isBillingMagicLinkURL, isClassicMagicLinkURL } from 'common/url'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { RulAi } from 'rulAi'
import styled from 'styled-components'

import { PATHS } from 'components/app/paths'
import { BasePage } from 'components/common/base-page'
import { PrimaryButton } from 'components/common/buttons'
import { StyledCardContainer } from 'components/common/card'
import { StyledGridContainer } from 'components/common/grid'
import { Style } from 'components/common/react-types'
import { StyledParagraph } from 'components/common/text'
import { StyledPageTitle, StyledSectionTitle } from 'components/common/title'
import { newPalette } from 'components/constants/colors'
import { device } from 'components/constants/sizes'
import {
  LandingPageConstants,
  RecentPurchaseCardConstants,
  UnrecognizedChargeCardConstants,
} from 'components/pages/landing-page/constants'
import { IconKeys, IconWithText } from 'components/pages/landing-page/landing-page-icons'

const StyledLink = styled.a`
  color: ${newPalette.whiteAlpha[95]};
`

const StyledLandingPageGridContainer = styled(StyledGridContainer)`
  margin-bottom: 60px;

  @media only screen and ${device.small} {
    row-gap: 20px;
  }
  @media only screen and ${device.medium}, ${device.large} {
    row-gap: 24px;
  }
`

const StyledCardGridItem = styled(StyledCardContainer)({
  padding: 24,
  [`@media only screen and ${device.small}, ${device.large}`]: {
    gridColumnEnd: 'span 6',
  },
  [`@media only screen and ${device.medium}`]: {
    gridColumnEnd: 'span 8',
  },
})

const StyledCardTitle = styled.div({
  fontStyle: 'normal',
  fontWeight: '500',
  color: newPalette.whiteAlpha[95],
  [`@media only screen and ${device.small}`]: {
    marginBottom: 32,
    fontSize: 16,
    lineHeight: '24px',
  },
  [`@media only screen and ${device.medium}`]: {
    marginBottom: 40,
    fontSize: 16,
    lineHeight: '24px',
  },
  [`@media only screen and ${device.large}`]: {
    marginBottom: 40,
    fontSize: 20,
    lineHeight: '30px',
  },
})

const StyledRecentPurchaseContentWrapper = styled.div({
  display: 'grid',
  rowGap: 32,
  columnGap: 0,
  [`@media only screen and ${device.small}`]: {
    marginBottom: 32,
    gridTemplateRows: 'repeat(5, 1fr)',
  },
  [`@media only screen and ${device.medium}`]: {
    marginBottom: 32,
    gridTemplateRows: 'repeat(3, 1fr)',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [`@media only screen and ${device.large}`]: {
    marginBottom: 40,
    gridTemplateRows: 'repeat(3, 1fr)',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

const StyledBottomParagraph = styled(StyledParagraph)({
  [`@media only screen and ${device.small}`]: {
    marginBottom: 32,
  },
  [`@media only screen and ${device.medium}`]: {
    marginBottom: 38,
  },
  [`@media only screen and ${device.large}`]: {
    marginBottom: 64,
  },
})

function RecentPurchaseCard(): JSX.Element {
  const navigate = useNavigate()

  return (
    <StyledCardGridItem>
      <StyledCardTitle>{RecentPurchaseCardConstants.CardTitle}</StyledCardTitle>
      <StyledRecentPurchaseContentWrapper>
        <IconWithText icon={IconKeys.refund} text={RecentPurchaseCardConstants.RefundIconText} />
        <IconWithText icon={IconKeys.profile} text={RecentPurchaseCardConstants.UpdateIconText} />
        <IconWithText
          icon={IconKeys.cancelSubscription}
          text={RecentPurchaseCardConstants.CancelSubscriptionIconText}
        />
        <IconWithText icon={IconKeys.receipt} text={RecentPurchaseCardConstants.ReceiptIconText} />
        <IconWithText icon={IconKeys.help} text={RecentPurchaseCardConstants.HelpIconText} />
      </StyledRecentPurchaseContentWrapper>
      <PrimaryButton text={RecentPurchaseCardConstants.ButtonText} onClick={() => navigate(PATHS.VERIFY_EMAIL)} />
    </StyledCardGridItem>
  )
}

function UnrecognizedChargeCard(): JSX.Element {
  const navigate = useNavigate()
  const navigateOptions = { state: { previousLocation: PATHS.ROOT } }

  return (
    <StyledCardGridItem>
      <StyledCardTitle>{UnrecognizedChargeCardConstants.CardTitle}</StyledCardTitle>
      <StyledParagraph style={{ marginBottom: '1.43em' }}>
        {UnrecognizedChargeCardConstants.FirstParagraph}
      </StyledParagraph>
      <StyledBottomParagraph>{UnrecognizedChargeCardConstants.SecondParagraph}</StyledBottomParagraph>
      <PrimaryButton
        text={UnrecognizedChargeCardConstants.ButtonText}
        onClick={() => navigate(PATHS.FIND_PURCHASE, navigateOptions)}
      />
    </StyledCardGridItem>
  )
}

export function LandingPage(): JSX.Element {
  const [searchParams] = useSearchParams()

  // We redirect magic links immediately to the purchases page. It is possible to refactor the magic links to have this
  // page in their url once paddle.net is live but there will always be some 'old' magic links coming to the homepage.
  if (isBillingMagicLinkURL(searchParams)) {
    return (
      <Navigate
        to={{
          // TODO: Once we have a transaction details page in the future we should redirect to the details page instead.
          pathname: PATHS.PURCHASES,
          search: searchParams.toString(),
        }}
        replace={true}
      />
    )
  }

  if (isClassicMagicLinkURL(searchParams)) {
    RulAi.openForTransaction({
      chatbotHash: searchParams.get('h'),
    })
  }

  const pageTitleStyle: Style = {
    marginBottom: 40,
  }
  const sectionTitleStyle: Style = {
    marginBottom: 17,
  }

  return (
    <BasePage>
      <StyledPageTitle style={pageTitleStyle}>{LandingPageConstants.PageTitle}</StyledPageTitle>
      <StyledLandingPageGridContainer>
        <RecentPurchaseCard />
        <UnrecognizedChargeCard />
      </StyledLandingPageGridContainer>
      <StyledSectionTitle style={sectionTitleStyle}>{LandingPageConstants.SectionTitle}</StyledSectionTitle>
      <StyledParagraph>
        {LandingPageConstants.Paragraph}{' '}
        <StyledLink href="https://www.paddle.com/about/why-has-paddle-charged-me" target="_blank">
          {LandingPageConstants.FAQLink}
        </StyledLink>
      </StyledParagraph>
    </BasePage>
  )
}
