const breakpoint = {
  medium: '768px',
  large: '1120px',
}

export const device = {
  small: `(max-width: ${breakpoint.medium})`,
  medium: `(min-width: ${breakpoint.medium}) and (max-width: ${breakpoint.large})`,
  large: `(min-width: ${breakpoint.large})`,
}
