import * as Sentry from '@sentry/react'
import { useRouteError } from 'react-router-dom'

import { GenericErrorPage } from 'components/pages/generic-error-page/generic-error-page'

/*
 * This is a bit of fudge to allow Sentry to work properly with React Router. It works for now, but
 * we may want to improve it later.
 *
 * Relevant link: https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
 */

const ThrowComponent = () => {
  throw useRouteError()
}

export const ErrorElement = () => {
  return (
    <Sentry.ErrorBoundary fallback={<GenericErrorPage />}>
      <ThrowComponent />
    </Sentry.ErrorBoundary>
  )
}
