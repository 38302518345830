import React from 'react'
import { useLocation } from 'react-router-dom'
import { RulAi } from 'rulAi'
import { RulAiVariableName } from 'rulAi/types'

/**
 * Set of params accepted by rul.ai bot to identify a transaction.
 * There are two main situations that use a different set of params:
 *    -> Paddle Classic: uses the `h` param.
 *    -> Paddle Billing: uses a combination of `token` together with either `sub` or `txn`, with an
 *       optional `type`.
 */
const AcceptedVariables = {
  h: RulAiVariableName.Hash,
  token: RulAiVariableName.Token,
  type: RulAiVariableName.EmailType,
  sub: RulAiVariableName.SubscriptionId,
  txn: RulAiVariableName.TransactionId,
}

export const useStoreUrlVariables = () => {
  const { search } = useLocation()

  React.useEffect(() => {
    Object.entries(AcceptedVariables).forEach(([parameter, name]) => {
      const value = new URLSearchParams(search).get(parameter)

      if (!!value) {
        RulAi.setVariable(name, value)
      }
    })
  })
}
