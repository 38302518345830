import { v4 as uuidv4 } from 'uuid'

export async function fetchResource(resource: RequestInfo | URL, config?: RequestInit) {
  const url = resource.toString()

  // Add random correlation id, so we can match FE with BE requests
  const correlationId = uuidv4()

  const headers = Object.assign(
    {},
    {
      'Content-Type': 'application/json',
      'X-Transaction-ID': correlationId,
    },
    config?.headers,
  )

  config = {
    ...(config ?? {}),
    headers,
  }

  return await fetch(url, config)
}
