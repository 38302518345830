import { Form } from 'antd'
import { CurrencyOption } from 'common/currency'
import { useEffect, useState } from 'react'
import { useTurnstile } from 'react-turnstile'
import styled from 'styled-components'
import { Nullable } from 'types/nullable'

import { PrimaryButton } from 'components/common/buttons'
import { SelectOption } from 'components/common/input/select'
import { TurnstileFormItem } from 'components/common/turnstile/form-item'
import { device } from 'components/constants/sizes'
import { PaymentMethods } from 'components/pages/find-purchase-page/constants'
import { StaticText, FieldNames } from 'components/pages/find-purchase-page/payment-form/constants'
import { AmountPaidFormItem } from 'components/pages/find-purchase-page/payment-form/fields/amount-paid'
import { KoreanCardBrandFormItem } from 'components/pages/find-purchase-page/payment-form/fields/korean-card-brand'
import { LastCardDigitsFormItem } from 'components/pages/find-purchase-page/payment-form/fields/last-card-digits'
import { PaymentDateFormItem } from 'components/pages/find-purchase-page/payment-form/fields/payment-date'
import { PaymentMethodFormItem } from 'components/pages/find-purchase-page/payment-form/fields/payment-method'

const FormFieldsWrapper = styled.div`
  display: grid;
  row-gap: 40px;
  margin-bottom: 40px;

  @media only screen and ${device.small}, ${device.medium} {
    grid-template-rows: repeat(4, 66px);
  }
  @media only screen and ${device.large} {
    column-gap: 20px;
    grid-template-rows: repeat(2, 66px);
    grid-template-columns: repeat(2, 1fr);
  }
`

export interface PaymentFormProps {
  currencies: Array<CurrencyOption>
  paymentMethods: Array<SelectOption>
  koreanCardBrands: Array<SelectOption>
  isRequestLoading: boolean
  didRequestFail: boolean
  onSubmit: (
    paidAmount: number,
    currencyCode: string,
    paymentDate: string,
    paymentMethod: string,
    koreanCardBrand: Nullable<string>,
    lastCardDigits: Nullable<string>,
    turnstileToken: string,
  ) => void
}

export function PaymentForm({
  currencies,
  paymentMethods,
  koreanCardBrands,
  isRequestLoading,
  didRequestFail,
  onSubmit,
}: PaymentFormProps): JSX.Element {
  const [form] = Form.useForm()
  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={{
        [FieldNames.PaidAmount]: '',
        [FieldNames.CurrencyCode]: currencies[0].code,
        [FieldNames.PaymentMethod]: paymentMethods[0].value,
        [FieldNames.LastCardDigits]: '',
        [FieldNames.TurnstileToken]: '',
      }}
    >
      <FormFieldsWrapper>
        <AmountPaidFormItem currencies={currencies} isDisabled={isDisabled} />
        <PaymentDateFormItem isDisabled={isDisabled} />
        <PaymentMethodFormItem paymentMethods={paymentMethods} isDisabled={isDisabled} />
        <LastCardDigitsFormItem isDisabled={isDisabled} />
        <KoreanCardBrandFormItem brands={koreanCardBrands} isDisabled={isDisabled} />
      </FormFieldsWrapper>
      <TurnstileFormItem action="search-by-details" fieldName={FieldNames.TurnstileToken} />
      <Form.Item noStyle>
        <SubmitPaymentDetailsButton
          currencies={currencies}
          isRequestLoading={isRequestLoading}
          didRequestFail={didRequestFail}
          onSubmit={onSubmit}
          setIsDisabled={setIsDisabled}
        />
      </Form.Item>
    </Form>
  )
}

interface SubmitPaymentDetailsButtonProps {
  currencies: Array<CurrencyOption>
  isRequestLoading: boolean
  didRequestFail: boolean
  onSubmit: PaymentFormProps['onSubmit']
  setIsDisabled(isDisabled: boolean): void
}

function SubmitPaymentDetailsButton({
  currencies,
  isRequestLoading,
  didRequestFail,
  onSubmit,
  setIsDisabled,
}: SubmitPaymentDetailsButtonProps): JSX.Element {
  const form = Form.useFormInstance()
  const values = Form.useWatch([], form)

  const [isSubmittable, setIsSubmittable] = useState(false)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsSubmittable(true),
      () => setIsSubmittable(false),
    )
  }, [form, values])

  const turnstile = useTurnstile()

  useEffect(() => {
    if (isRequestLoading) {
      setIsDisabled(true)
      setIsSubmittable(false)
    } else if (didRequestFail) {
      setTimeout(() => {
        setIsDisabled(false)
        setIsSubmittable(true)
        form.resetFields([FieldNames.TurnstileToken])
        turnstile?.reset()
      }, 5000)
    } else {
      setIsDisabled(false)
      setIsSubmittable(true)
      form.resetFields([FieldNames.TurnstileToken])
      turnstile?.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestLoading, didRequestFail, setIsDisabled])

  const onClick = () => {
    if (isSubmittable) {
      const paidAmount = form.getFieldValue(FieldNames.PaidAmount)
      const currencyCode = form.getFieldValue(FieldNames.CurrencyCode)
      const paymentDate = form.getFieldValue(FieldNames.PaymentDate)
      const paymentMethod = form.getFieldValue(FieldNames.PaymentMethod)
      const koreanCardBrand = form.getFieldValue(FieldNames.KoreanCardBrand)
      const lastCardDigits = form.getFieldValue(FieldNames.LastCardDigits)
      const turnstileToken = form.getFieldValue(FieldNames.TurnstileToken)

      const decimalPlaces = currencies.find(({ code }) => code === currencyCode)?.decimalPlaces ?? 0
      const convertedPaidAmount = Math.round(parseFloat(paidAmount) * 10 ** decimalPlaces)

      const formattedPaymentDate = paymentDate.format('YYYY-MM-DD')

      const maybeLastCardDigits = paymentMethod === PaymentMethods.Card ? lastCardDigits : null
      const maybeKoreanCardBrand = paymentMethod === PaymentMethods.SouthKorea ? koreanCardBrand : null

      onSubmit(
        convertedPaidAmount,
        currencyCode,
        formattedPaymentDate,
        paymentMethod,
        maybeKoreanCardBrand,
        maybeLastCardDigits,
        turnstileToken,
      )
    }
  }

  return (
    <PrimaryButton
      text={StaticText.SubmitLabel}
      isLoading={isRequestLoading}
      isDisabled={!isSubmittable}
      onClick={onClick}
    />
  )
}
