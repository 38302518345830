import { assertUnreachable } from 'components/common/errors'
import { SmallGenericIcon } from 'components/common/icon'
import { ObjectValues } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'
import CompleteSvg from 'components/images/icons/complete.svg'
import DisputedSvg from 'components/images/icons/disputed.svg'
import FailedSvg from 'components/images/icons/failed.svg'
import InvoiceSentSvg from 'components/images/icons/invoice-sent.svg'
import PastDueSvg from 'components/images/icons/past-due.svg'
import RefundedSvg from 'components/images/icons/refunded.svg'
import { GenericStatus, SharedStatusProps } from 'components/pages/purchases-page/status'

const TransactionStatusIconComponentTitles = {
  complete: 'Complete transaction icon',
  disputed: 'Disputed transaction icon',
  invoiceSent: 'Invoice sent icon',
  pastDue: 'Past due icon',
  refunded: 'Refunded icon',
  failed: 'Payment failed icon',
} as const

const TransactionStatusSmallIconComponents = {
  complete: <SmallGenericIcon imgSource={CompleteSvg} imgTitle={TransactionStatusIconComponentTitles.complete} />,
  disputed: <SmallGenericIcon imgSource={DisputedSvg} imgTitle={TransactionStatusIconComponentTitles.disputed} />,
  invoiceSent: (
    <SmallGenericIcon imgSource={InvoiceSentSvg} imgTitle={TransactionStatusIconComponentTitles.invoiceSent} />
  ),
  pastDue: <SmallGenericIcon imgSource={PastDueSvg} imgTitle={TransactionStatusIconComponentTitles.pastDue} />,
  refunded: <SmallGenericIcon imgSource={RefundedSvg} imgTitle={TransactionStatusIconComponentTitles.refunded} />,
  failed: <SmallGenericIcon imgSource={FailedSvg} imgTitle={TransactionStatusIconComponentTitles.failed} />,
} as const

function RefundedStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.refunded}
      textColor={newPalette.purple[200]}
      background={newPalette.purple[200]}
    />
  )
}

function PaidStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.complete}
      textColor={newPalette.green[200]}
      background={newPalette.green[200]}
    />
  )
}

function RefundRequestedStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.refunded}
      textColor={newPalette.gray[200]}
      background={newPalette.gray[500]}
    />
  )
}

function UnpaidStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.invoiceSent}
      textColor={newPalette.blue[300]}
      background={newPalette.blue[300]}
    />
  )
}

function PastDueStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.pastDue}
      textColor={newPalette.red[300]}
      background={newPalette.red[300]}
    />
  )
}

function DisputedStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.disputed}
      textColor={newPalette.red[300]}
      background={newPalette.red[300]}
    />
  )
}

function FailedPaymentStatus(props: SharedStatusProps): JSX.Element {
  return (
    <GenericStatus
      text={props.text}
      icon={TransactionStatusSmallIconComponents.failed}
      textColor={newPalette.gray[200]}
      background={newPalette.gray[500]}
    />
  )
}

export const TransactionStatusTypes = {
  paid: 'paid',
  pastDue: 'past_due',
  failedPayment: 'failed_payment',
  unpaid: 'unpaid_invoice',
  disputed: 'disputed',
  fullRefund: 'full_refund',
  partialRefund: 'partial_refund',
  taxRefund: 'tax_refund',
  refundRequested: 'refund_requested',
  taxRefundRequested: 'tax_refund_requested',
} as const

export type TransactionStatusTypeKeys = ObjectValues<typeof TransactionStatusTypes>

interface TransactionStatusProps {
  type: TransactionStatusTypeKeys
}

export function TransactionStatus(props: TransactionStatusProps) {
  switch (props.type) {
    case TransactionStatusTypes.paid:
      return <PaidStatus text={'Paid'} />
    case TransactionStatusTypes.pastDue:
      return <PastDueStatus text={'Past due'} />
    case TransactionStatusTypes.failedPayment:
      return (
        <>
          <PastDueStatus text={'Past due'} />
          <div style={{ marginLeft: 12, display: 'inline-block' }}>
            <FailedPaymentStatus text={'Failed Payment'} />
          </div>
        </>
      )
    case TransactionStatusTypes.fullRefund:
      return <RefundedStatus text={'Refunded'} />
    case TransactionStatusTypes.partialRefund:
      return <RefundedStatus text={'Partially refunded'} />
    case TransactionStatusTypes.taxRefund:
      return <RefundedStatus text={'Tax refunded'} />
    case TransactionStatusTypes.refundRequested:
      return <RefundRequestedStatus text={'Refund requested'} />
    case TransactionStatusTypes.taxRefundRequested:
      return <RefundRequestedStatus text={'Tax refund requested'} />
    case TransactionStatusTypes.unpaid:
      return <UnpaidStatus text={'Unpaid invoice'} />

    case TransactionStatusTypes.disputed:
      return <DisputedStatus text={'Disputed'} />

    default:
      return assertUnreachable('Invalid result type: ', props.type)
  }
}
