export const StaticText = {
  EmailAddressHeader: 'What’s your email address?',
  SubmitLabel: 'Locate my purchase',
} as const

export const ErrorMessages = {
  EmptyEmailAddress: 'An email address is required',
  InvalidEmailAddress: 'Invalid email address',
} as const

export const FieldNames = {
  EmailAddress: 'emailAddress',
  TurnstileToken: 'turnstileToken',
} as const
