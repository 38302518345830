import { FormatISODate } from 'common/date'
import styled from 'styled-components'

import { assertUnreachable } from 'components/common/errors'
import { SmallGenericIcon } from 'components/common/icon'
import { ObjectValues } from 'components/common/react-types'
import { colors } from 'components/constants/colors'
import CancelSvg from 'components/images/icons/cancel.svg'
import PauseSvg from 'components/images/icons/pause.svg'
import ResumeSvg from 'components/images/icons/resume.svg'

const StyledContainer = styled.div`
  padding: 4px;
  display: inline-flex;
  column-gap: 8px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.padBlue[800]};
  background: ${colors.padBlue[200]};
`

export const ScheduledChangeTypes = {
  cancel: 'cancel',
  pause: 'pause',
  resume: 'resume',
} as const

export type ScheduledChangeTypesKeys = ObjectValues<typeof ScheduledChangeTypes>

interface ScheduledChangeMessageProps {
  type: ScheduledChangeTypesKeys
  date: string
}

export const ScheduledChangeMessage = ({ type, date }: ScheduledChangeMessageProps) => {
  switch (type) {
    case ScheduledChangeTypes.cancel: {
      return (
        <StyledContainer>
          {ScheduledChangeSmallIconComponents.cancel}
          {cancellationMessage(date)}
        </StyledContainer>
      )
    }

    case ScheduledChangeTypes.pause: {
      return (
        <StyledContainer>
          {ScheduledChangeSmallIconComponents.pause}
          {pauseMessage(date)}
        </StyledContainer>
      )
    }

    case ScheduledChangeTypes.resume: {
      return (
        <StyledContainer>
          {ScheduledChangeSmallIconComponents.resume}
          {resumeMessage(date)}
        </StyledContainer>
      )
    }

    default: {
      return assertUnreachable('Invalid scheduled changes type: ', type)
    }
  }
}

const ScheduledChangeIconComponentTitles = {
  cancel: 'Scheduled cancellation icon',
  pause: 'Scheduled pause icon',
  resume: 'Scheduled resumption icon',
} as const

const ScheduledChangeSmallIconComponents = {
  cancel: <SmallGenericIcon imgSource={CancelSvg} imgTitle={ScheduledChangeIconComponentTitles.cancel} />,
  pause: <SmallGenericIcon imgSource={PauseSvg} imgTitle={ScheduledChangeIconComponentTitles.pause} />,
  resume: <SmallGenericIcon imgSource={ResumeSvg} imgTitle={ScheduledChangeIconComponentTitles.resume} />,
} as const

const cancellationMessage = (date: string) => {
  return 'Scheduled cancellation on ' + FormatISODate(date)
}

const pauseMessage = (date: string) => {
  return 'Scheduled pause on ' + FormatISODate(date)
}

const resumeMessage = (date: string) => {
  return 'Scheduled to resume on ' + FormatISODate(date)
}
