import styled from 'styled-components'
import { Nullable } from 'types/nullable'

import { SecondaryLoadingSpinner } from 'components/common/loading-spinner'
import { Style } from 'components/common/react-types'
import { newPalette } from 'components/constants/colors'

const TURNSTILE_LOADER_TEXT = 'Verifying your humanity...'

const TurnstileLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  color: ${newPalette.whiteAlpha[95]};
`

export interface TurnstileLoaderProps {
  isVisible: boolean
  style?: Style
}

export function TurnstileLoader({ isVisible, style }: TurnstileLoaderProps): Nullable<JSX.Element> {
  if (!isVisible) {
    return null
  }

  return (
    <TurnstileLoaderContainer style={style}>
      {TURNSTILE_LOADER_TEXT}
      <SecondaryLoadingSpinner />
    </TurnstileLoaderContainer>
  )
}
