export const FindPurchasePageConstants = {
  MainPageTitle: 'Sorry you’re having trouble',
  AlternativePageTitle: 'Let’s try another way',
  MainPageParagraph: 'Provide a few details about the transaction to help our team investigate the issue *',
  AlternativePageParagraph: 'Don’t worry, we can still locate your transaction. We just need a few more details *',
  DisclaimerParagraph:
    '* We’ll only ever use your information to investigate why Paddle has charged you. We promise never to store or use the information you provide for any other purpose',
} as const

export const PaymentMethods = {
  Card: 'card',
  SouthKorea: 'korea_local',
  PayPal: 'paypal',
  ApplePay: 'apple_pay',
  GooglePay: 'google_pay',
  KakaoPay: 'kakaopay',
  NaverPay: 'naverpaypoint',
  SamsungPay: 'samsungpay',
  Payco: 'payco',
  Alipay: 'alipay',
  Ideal: 'ideal',
  Bancontact: 'bancontact',
} as const

export const PaymentMethodLabels = {
  [PaymentMethods.Card]: 'Credit or debit card',
  [PaymentMethods.SouthKorea]: 'Credit or debit card (Korean)',
  [PaymentMethods.PayPal]: 'PayPal',
  [PaymentMethods.ApplePay]: 'Apple Pay',
  [PaymentMethods.GooglePay]: 'Google Pay',
  [PaymentMethods.KakaoPay]: 'Kakao Pay',
  [PaymentMethods.NaverPay]: 'Naver Pay',
  [PaymentMethods.SamsungPay]: 'Samsung Pay',
  [PaymentMethods.Payco]: 'Payco',
  [PaymentMethods.Alipay]: 'Alipay',
  [PaymentMethods.Ideal]: 'iDEAL',
  [PaymentMethods.Bancontact]: 'Bancontact',
} as const

export const KoreanCardBrandLabels = {
  bc: 'BC',
  citi: 'Citi',
  hana: 'Hana',
  hyundai: 'Hyundai',
  jeju: 'Jeju',
  jeonbuk: 'Jeonbuk',
  kakaobank: 'Kakao Bank',
  kbank: 'Kbank',
  kdbbank: 'KDB Bank',
  kookmin: 'Kookmin',
  kwangju: 'Kwangju',
  lotte: 'Lotte',
  mg: 'mg',
  naverpaycard: 'Naver Pay',
  nh: 'NH',
  post: 'Post',
  samsung: 'Samsung',
  savingsbank: 'Savings bank',
  shinhan: 'Shinhan',
  shinhyup: 'Shinhyup',
  suhyup: 'Suhyup',
  tossbank: 'Toss Bank',
  woori: 'Woori',
} as const
